@import "src/styles/variables";

.password-reset-link {
    margin-top: 8rem;
    h2 {
        color: white;
        font-weight: bolder;
        text-align: center;
        margin-bottom: 1rem;
    }
    .password-reset-link__info {
        color: white;
    }
    .password-reset-link__controllers {
        margin-top: 8rem;
    }
    .password-reset-link__wrong {
        color: white;
		text-align: center;
		margin-top: 1rem;
		span {
			color: $secondary-color;
			cursor: pointer;
		}
    }
}
