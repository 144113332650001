.video-card__container {
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    height: 100%;
    background-color: white;
    box-shadow: 0px 10px 10px #6666660f;

    .thumbnail__wrapper {
        width: 100%;
        position: relative;
        text-align: center;

        .thubnai-image {
            width: 100%;
            height: 170px;
            object-fit: cover;
        }

        .play {
            position: absolute;
            top: 35%;
            left: 35%;
        }
    }

    .note {
        background-color: white;
        padding: 0.5rem 0.8rem;

        .subject {
            display: flex;
            font-size: 14px;
            margin: auto auto 5px;

            img {
                margin-right: 8px;
            }
        }

        .content {
            color: #2b2b2b;
            font-weight: 600;
            font-size: 14px;
            margin: auto auto 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
    }

    .disable-content {
        // pointer-events: none;
        opacity: 0.4;
    }
}

@media only screen and (max-width: 1024px) {
    .video-card__container {
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
        width: 100%;
        height: 100%;
        background-color: white;
        box-shadow: 0px 10px 10px #6666660f;

        .thumbnail__wrapper {
            width: 100%;
            position: relative;
            text-align: center;

            .thubnai-image {
                width: 100%;
                height: 170px;
                object-fit: cover;
            }

            .play {
                position: absolute;
                top: 35%;
                left: 20%;
            }
        }

        .note {
            background-color: white;
            padding: 0.5rem 0.8rem;

            .subject {
                display: flex;
                font-size: 14px;
                margin: auto auto 5px;

                img {
                    margin-right: 8px;
                }
            }

            .content {
                color: #2b2b2b;
                font-weight: 600;
                font-size: 14px;
                margin: auto auto 5px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .video-card__container {
        .thumbnail__wrapper {
            .play {
                top: 35%;
                left: 35%;
            }
        }
    }
}
