@import "src/styles/variables";

.ant-layout-sider-children {
  height: 100vh !important;
  // width: 100% !important;
  // position: absolute !important;

  background-color: $white-color !important;

  .ant-menu-item {
    height: 50px !important;
    width: 90% !important;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    padding-left: 35px !important;
    padding-top: 5px !important;

    span {
      color: #7e899e;
      font-size: 18px;
      margin-left: 10px;
    }
  }

  .ant-menu-submenu-title {
    color: #7e899e;
    font-size: 18px;
    margin-left: 10px;
    margin-bottom: 5% !important;
    span {
      color: #7e899e;
    }
    span:first-child {
      margin-right: 25px;
    }
  }
  .ant-menu-item-selected {
    // border-left: 3px solid white !important;
    span {
      color: white !important;
    }
  }
  ul {
    background-color: $white-color !important;
    span {
      color: $text-light-color;
    }
  }
  .ant-menu-item-selected {
    background: transparent linear-gradient(260deg, #5839CB 0%, #32169B 100%) 0% 0% no-repeat padding-box !important;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    width: 90% !important;
  }

  .ant-menu-item-selected.ssvmkidscasa {
    background: #136367 !important;
  }

  .sidebar__icon {
    margin: auto 8px auto auto !important;
    margin-bottom: 5px;
    width: 16px;
    height: 16px;
  }
}

.sidebar-pj {
  background-color: #fff;
  position: sticky !important;
  top: 0px;
  text-align: center;
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    .logo-wrapper {
      width: 160px;
      height: 50px;
      margin: 3rem auto 1.5rem auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .ant-menu{
      overflow: auto;
    }
  }

  .sidebar-item__btm {
    width: 100%;
    position: absolute;
    bottom: 2vh;
    padding: 5%;
    cursor: pointer;
    .sidebar-dp {
      img {
        border-radius: 25px;
        height: 50px;
        width: 50px;
      }
    }
    // .ant-menu-item-group-title {
    //   padding: 10px !important;
    // }
    // :first-child {
    //   img {
    //     border-radius: 100px !important;
    //     height: 3rem;
    //   }
    // }

    .student-info {
      margin-top: 10%;
      p {
        color: $text-color;
      }
      .name {
        font-weight: bolder;
      }
      .class-studied {
        margin-top: 20px;
        color: #222222;
        font-size: 11px;
      }
    }
    // .sidebar-profile__wrapper {
    // }
  }

  .logout {
    margin-top: 1.5rem;
    p {
      font-weight: bold;
      text-align: center;
      color: #222222;
      font-size: 18px;
    }
  }
}

.ant-layout-sider {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
  background-color: $white-color !important;
}

@media only screen and (max-width: 768px) {
  .layout {
    display: flex;
    flex-direction: column;
  }

  .layout .ant-layout {
    flex: 1;
  }

  .sidebar {
    background-color: #f0f2f5;
    height: 200px; /* Adjust this based on your requirement */
    position: sticky;
    bottom: 0;
  }

  .ant-layout-sider {
    position: absolute !important;
        bottom: 0;
        z-index: 1;
        transition: all 0.2s !important;
  }

  .sidebar-pj {
    .ant-layout-sider-children {
      .logo-wrapper {
        margin: 1rem auto 1.5rem auto;
        img {
          content: url('../../../assets/images/app-icon-white.svg') !important;
        }
    }
    }
  }
}

.ant-badge {
  span {
    margin-left: 0px !important;
  }

  .ant-menu-item-selected {
    span {
      color: white !important;
    }
  }
  
  .ant-scroll-number.ant-badge-count {
    top: 6px;
    right: -17px;
    span {
      color: white !important;
    }
  }
}

.ant-menu-item-icon {
  span {
    color: #7e899e;
    font-size: 18px;
    margin-left: 10px;
  }
}