.list__subjects {
  border-radius: 10px;
  background-color: white;
  height: 65vh;
  margin: 0 2%;
  .content__left {
    border-right: 1px solid rgb(243, 243, 243);
    height: 100%;
    overflow-x: auto;
    // -ms-overflow-style: none;
    // scrollbar-width: none;
    // &::-webkit-scrollbar {
    //   display: none;
    // }

    .content-left__header {
      height: 20%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 3rem;
      p {
        font-size: 20px;
        font-weight: 600;
        margin: 0;
      }
      span {
        font-size: 24px;
      }
    }
    .subject__card {
      padding: 2rem 3rem;
      p {
        font-size: 16px;
        font-weight: 500;
      }

      .subjects-details__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 50%;
        p {
          margin: 0;
          font-size: 14px;
          font-weight: 500;
          color: #54c1e3;
        }
        .progress__wrapper {
          .ant-progress {
            width: 30%;
          }
          display: flex;
          width: 60%;
          p {
            color: #56c2b8;
            margin: 0;
            margin-left: 10%;
          }
        }
      }
    }
    .subject-card__active {
      background-color: #1cc29e;
      p {
        color: white;
      }
      .subjects-details__container {
        p {
          color: white;
        }
        .progress__wrapper {
          p {
            color: white;
          }
        }
      }
    }
  }
  .content__right {
    height: 100%;
    overflow-x: auto;
    padding-bottom: 5%;
    // -ms-overflow-style: none;
    // scrollbar-width: none;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    .content-right__header {
      padding: 3rem 3rem 1rem 3rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .analytics__subject-dropdown {
        display: none;
      }

      p {
        font-size: 20px;
        font-weight: 600;
        margin: 0;
      }
      .content-right__btn {
        border: 1px solid #5839cb;
        padding: 2px 5px;
        border-radius: 10px;
        font-size: 12px;
        width: fit-content;
        color: #5839cb;
        cursor: pointer;
        span {
          font-size: 9px;
        }
      }
    }
  }
}

.ant-spin-spinning {
  margin: auto auto !important;
}

@keyframes search {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.content-left__search {
  height: 20%;
  padding: 0 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .content-left-search__icon {
    color: #abacad;
    margin-left: 5%;
  }
  .content-left-search__input {
    background: #f2f3f5;
    border-radius: 10px;
    border: none;
    box-shadow: none;
    text-indent: 20px;
    padding: 1rem 0;
    animation-name: search;
    animation-duration: 500ms;
    input {
      background: transparent;
      text-indent: 10px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .application-body-two-bgs {
    // background: unset !important;
  }
  .application-body {
    // background: unset !important;
    .navbar__container {
      .app-logo {
        display: none;
      }
    }
  }
  .list__subjects {
    height: 100vh;
    margin: unset;

    .select-subject {
      display: none;
    }

    .content__right {
      height: 90vh;

      .content-right__header {
        padding: 2rem 5rem 0rem 1rem;
        .subject-name {
          display: none;
        }
        .analytics__subject-dropdown {
          z-index: 1;
          display: block;

          .ant-select-selector {
            height: 2rem !important;
            border: 1px solid #d9d9d9 !important;
            border-radius: 10px;
          }
        }
      }
    }
  }

  .profile__container {
    margin: 0px 2px;
  }
}
