@import "src/styles/variables";

.profile__card {
  background: url("../../../../assets/images/profile-card-bg2.svg") right bottom
      no-repeat,
    url("../../../../assets/images/profile-card-bg1.svg") left top no-repeat,
    transparent linear-gradient(263deg, #32169b 0%, #120056 100%) 0% 0%
      no-repeat padding-box;
  background-position-x: 70%;
  background-position-y: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  padding: 3%;
  margin-top: 3%;
  .switch-profile__btn {
    height: 40px;
    background: #5839cb 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #8364f5;
    border-radius: 10px;
    color: white;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      width: 15px;
      height: 15px;
      margin-right: 0.5rem;
    }
  }
  .profile-card__details {
    display: flex;
    //  justify-content: space-between;
    width: 60%;
  }
  .profile-card-details-student-image__container {
    position: relative;
    margin-left: 5%;
  }
  .profile-card-details-student-details__container {
    margin-left: 3%;
  }
  .profile-card-details-student__name {
    font-size: 32px;
    color: white;
    margin: 0;
  }
  .profile-card-details-student__dob {
    color: #ffffff;
    opacity: 0.7;
    font-size: 16px;
    margin: 0;
  }
  .profile-card-student__image {
    width: 80px;
    height: 80px;
    border-radius: 40px;
  }
  .profile-card-rocket__container {
    position: absolute;
    top: -25%;
    animation: linear forwards;
    animation-name: run;
    animation-duration: 1s;
    img {
      width: 250px;
      height: 200px;
    }
  }
  @keyframes run {
    0% {
      right: 20%;
    }
    25% {
      right: 15%;
    }
    50% {
      right: 10%;
    }
    75% {
      right: 7%;
    }
    100% {
      right: 5%;    
    }
  }
  .profile-card-details__edit {
    margin-left: 2rem;
    padding: 2.5px 8px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 30px;
    img {
      width: 11px;
      height: 11px;
    }
  }
  .profile-card-details__save {
    background-color: $secondary-btn-color;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #8364F5;
    border-radius: 10px;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    p {
      margin: 0;
      color: white;
    }
  }
  .profile-card-student-image__edit {
    background-color: $secondary-btn-color;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 25px;
    width: 32px;
    height: 32px;
    text-align: center;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 65%;
  }
}
.profile-card-details__datepicker {
  background: transparent !important;
  border: none !important;
  border-radius: 10px !important;
  text-indent: 0 !important;
  height: 50px !important;
  outline: none !important;
  padding: 0 !important;
  input {
    color: white !important;
  }

  .anticon-calendar {
    color: white !important;
  }
  .ant-picker-suffix {
    margin-left: -15px;
  }
  .ant-picker-clear {
    background: linear-gradient(263deg, #145ce7 0%, #0f4ec7 100%) 0% 0%
      no-repeat padding-box !important;
    color: white !important;
  }
  ::placeholder {
    color: white !important;
  }
}
.ant-picker-focused {
  box-shadow: none !important;
}
.profile-card-details-name__input {
  background: transparent !important;
  border: none !important;
  border-radius: 10px !important;
  text-indent: 0 !important;
  height: 50px !important;
  outline: none !important;
  padding: 0 !important;
  box-shadow: none !important;
  font-size: 32px !important;
  color: white !important;
  margin: 0 !important;
  width: 40% !important;
}
