.checkbox-field__container {
    .uncheck {
        color: transparent;
        border: 2px solid rgba(255, 255, 255, 0.5);
        width: 20px;
        height: 20px;
        border-radius: 10px;
    }
    .check {
        font-size: 20px;
    }
}