@import "src/styles/variables";

.assignment-details {
  .assignment-details-info {
    padding: 1rem 2rem 2rem;
    height: 100%;

    h3 {
      color: #fff;
      font-weight: 600;
    }
  }

  .no-assignment {
    h3 {
      color: #fff;
    }
  }

  .details-card {
    background-color: #fff;
    padding: 0 24px;
    box-shadow: 2px 2px 10px #0f47b20f;
    border-radius: 10px;
    margin-top: 24px;
    height: 80%;


    .__sub_title {
      font-size: 12px;
      font-weight: bold;
      padding: 0px;
      line-height: 1.2;
      color: #56595f;
    }

    .card-button__container {
      padding: 24px;

      .work__title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .type__title {
        font-size: 14px;
        padding-left: 10%;
        margin-bottom: 10px;
        color: #5539CB;
      }

      .button__group {
        display: flex;

        .action__button {
          margin: 5px;
          max-width: 50%;
        }

        .ant-btn[disabled] {
          color: rgb(0 0 0 / 40%) !important;
          // border-color: #a86969 !important;
          background: #d3d2d2 !important;
        }
      }


      .activity-turned_file {
        display: flex;
        cursor: pointer;
        margin: 2% 0%;
        color: #5539CB;

        .pdf_icon {
          height: 20px;
          width: 20px;
        }

        .file_title {
          margin-left: 15px;
        }

        &.download {
          color: $success-color;
        }
      }

    }

    .card-title__container {
      // border-right: 1px solid #eeeeee60;
      padding: 24px 0;
      border-bottom: 2px solid #5539CB;

      .assignment-card__title {
        display: flex;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;

        .__text {
          display: inline-grid;
        }


        img {
          width: 25px;
          height: 25px;
          margin-right: 10px;
          margin-bottom: 2px;

        }
      }

      .assignment-card-time__container {
        display: flex;
        justify-content: space-between;

        .assignment-card__time {
          color: $primary-grey;
          font-weight: 500;
          margin-bottom: 0;

          .anticon {
            margin-right: 8px;
          }
        }
      }
    }

    .card-file__container {
      padding: 10px 0;

      .assignment-card__instruction {
        font-size: 14px;
        margin-bottom: 10px;
      }

      .file__container {
        border-width: thin;
        border-style: solid;
        border-color: #5454548a;
        padding: 10px;
        border-radius: 8px;
        width: 50%;
        margin-bottom: 15px;

        .activity_file {
          display: flex;
          cursor: pointer;
          margin: 2% 0%;
          color: #5539CB;
          align-items: center;

          .pdf_icon {
            height: 30px;
            width: 30px;
          }

          .file_title {
            margin-left: 15px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &.download {
            color: $success-color;
          }

        }

        .file__thumbnail {
          position: relative;
          overflow: hidden;

          .thumbnail__image {
            transition: all 250ms ease-in;
            width: 100%;
            object-fit: contain;
          }
        }

        .info_text {
          font-size: 12px;
          font-weight: bold;
          display: flex;
          color: $primary-grey;
          padding: 10px;
          align-items: center;

          .ant-btn {
            height: 32px !important;
            margin-left: 5px;
            border: 0px !important;
          }

        }
      }
    }
  }

}