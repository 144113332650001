@import "src/styles/variables";

.live-class-info {
    .ant-drawer-header {
        display: none !important;
    }
    .ant-drawer-body {
        padding: 0 !important;
    }
    .ant-drawer-content-wrapper {
        box-shadow: none !important;
        height: 87.5vh !important;
    }
    .ant-drawer-content {
        border-radius: 10px 0 0 10px;
    }
    .live-class-info__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #200e52;
        height: 49px;
        padding-left: 28px;
        padding-right: 24.54px;
        .live-class-info-header__left {
            img,
            h4 {
                display: inline;
            }
            h4 {
                color: #fff;
                margin-bottom: 0;
                margin-left: 16px;
            }
            img {
                width: 18px;
                height: 18px;
            }
        }
        .live-class-info-header__right {
            img {
                cursor: pointer;
            }
        }
    }
    .live-class-info__content {
        padding: 24px 20px;
        p {
            margin-bottom: 0 !important;
        }
        .live-class__title {
            font-weight: 600;
        }
        .live-class__date {
            font-size: 14px;
            margin-top: 20px;
            color: $primary-grey;
            .anticon-clock-circle {
                margin-right: 8px;
                svg {
                    path {
                        &:first-child,
                        &:last-child {
                            fill: #584a7e !important;
                        }
                        &:nth-child(2) {
                            fill: #cfcad9 !important;
                        }
                    }
                }
            }
        }
        .live-class__date {
            .anticon-clock-circle {
                margin-right: 8px;
                svg {
                    path {
                        &:first-child, &:last-child {
                            fill: #584a7e !important;
                        }
                        &:nth-child(2) {
                            fill: #cfcad9 !important;
                        }
                    }
                }
            }
        }
        .live-class__tutors {
            margin-top: 20px;
            p {
                font-weight: 500;
                color: $primary-grey;
                font-size: 14px;
                margin-bottom: 10px !important;
            }
            span {
                margin-right: 5px;
                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
        .live-class__subject {
            margin-top: 26px;
            font-weight: 500;
            color: $primary-grey;
            font-size: 14px;
            span {
                margin-right: 10px;
                img {
                    width: 10px;
                    height: 13px;
                }
            }
        }
        .live-class__sections {
            margin-top: 20px;
            font-weight: 500;
            color: $primary-grey;
            font-size: 14px;
            span {
                margin-right: 10px;
                img {
                    width: 11px;
                    height: 12px;
                }
            }
        }
    }
}