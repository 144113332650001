@import 'src/styles/variables';

.quizes__container {
    background-color: white;
    padding-top: 5rem !important;
    position: relative;
    height: inherit;
    display: flex;
    flex-direction: column;

    .pagination__component {
        position: absolute;
        bottom: 2%;
        right: 1%;
    }

    .exercises-list__container {
        // padding-bottom: 5rem;
        overflow-y: auto;
    }
    .exercise-spin-loader {
        margin: 50% auto;
        transform: translateY(-50%);
        display: block;
    }

    .no-exercises-text {
        text-align: center;
        transform: translateY(-50%);
        margin: 50% auto;
    }

    .quizes__title__wrapper {
        padding: 25px 0px 0px;

        p,
        h2 {
            padding: 0px 30px;
        }

        h2 {
            font-weight: 600;
        }

        p {
            color: #7e899e;
            font-size: 18px;
        }

        &::after {
            content: ' ';
            display: block;
            border-bottom: 1px solid #0f47b20f;
        }
    }

    .start-quiz {
        visibility: hidden;
        color: #7e899e;
        font-size: 24px;
        padding-top: 10px;
    }

    .exercise-details {
        margin-top: 20px;
    }

    .exercise__wrapper {
        padding: 25px 30px 15px;
        cursor: pointer;

        border-bottom: 1px solid #0f47b20f;

        &:hover {
            background-color: #f2f3f5;

            .start-quiz {
                visibility: visible;
            }
        }

        .question__no {
            color: #7e899e;
            background-color: rgba(126, 137, 158, 0.1);
            border-radius: 100px;
            padding: 10px;
        }

        .question__no-viewed {
            color: $secondary-color;
            background-color: rgba(253, 144, 0, 0.1);
            border-radius: 100px;
            padding: 10px;
        }

        .question {
            font-weight: 500;
            font-size: 18px;
        }

        .question__opt {
            color: #7e899e;
            margin-right: 5%;

            span {
                img {
                    margin-top: -2px;
                    margin-right: 10px;
                    width: 11px;
                    height: 11px;
                }
            }
        }

        .q-time {
            color: #eda0a8;
        }

        .q-questions {
            color: #9e81b6;
        }

        .q-attempts {
            color: #54c1e3;
        }
    }
}

@media only screen and (max-width: 768px) {
    .quizes__container {
        padding-top: 0rem !important;
        .quizes__title__wrapper {
            display: none;
        }
        .exercise__wrapper {
            margin: 10px;
            border: 1px solid #8370f0 !important;
            border-radius: 10px;
            padding: 10px 31px 0px;

            .exercise-details {
                .question__opt.q-questions {
                    display: flex;
                }
            }

            .question__opt_attempts {
                display: none;
            }
        }
    }

    .ant-drawer-content-wrapper {
        width: 100% !important;
    }
    .ant-drawer-body {
        padding: 0px !important;
    }
}
