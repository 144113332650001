// @import "src/styles/variables";

// .video-wrapper{
//   button.video-react-play-control.video-react-control.video-react-button.video-react-playing,
//   button.video-react-play-control.video-react-control.video-react-button.video-react-paused,
//   button.video-react-control.video-react-button.video-react-icon.video-react-icon-replay-10.video-react-replay-control,
//   button.video-react-control.video-react-button.video-react-icon.video-react-icon-forward-10.video-react-forward-control
//   {
//     position: absolute;
//     left: 0;
//     right: 0;
//     margin: auto;
//     top: -43vh;
//     font-size: 40px;
//   }
//   button.video-react-control.video-react-button.video-react-icon.video-react-icon-replay-10.video-react-replay-control,
//   button.video-react-control.video-react-button.video-react-icon.video-react-icon-forward-10.video-react-forward-control
//   {
//     right: 30%;
//     font-size: 25px;
//     top: -40vh;
//   }
//   button.video-react-control.video-react-button.video-react-icon.video-react-icon-forward-10.video-react-forward-control{
//     left: 30%;
//     right: 0;
//   }
//   .video-react-control-bar.video-react-control-bar-auto-hide {
//     background: transparent;
//     width: 750px;
//     margin: 0 auto 30px auto;
//   }
//   button.video-react-icon-fullscreen.video-react-fullscreen-control.video-react-control.video-react-button.video-react-icon,
//   button.video-react-icon-fullscreen-exit.video-react-fullscreen-control.video-react-control.video-react-button.video-react-icon
//   {
//     font-size: 15px;
//     bottom: 7px
//   }
//   .video-react-current-time.video-react-time-control.video-react-control,
//   .video-react-duration.video-react-time-control.video-react-control
//   {
//     font-size: 14px;
//     bottom: 5px;
//   }
//   .video-react-time-control.video-react-time-divider{
//     font-size: 16px;
//     bottom: 15px;
//     display: none;
//   }
//   button.video-react-big-play-button.video-react-big-play-button-left{
//     position: absolute;
//     border: unset;
//     background: transparent;
//     top:0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     margin: auto;
//     font-size: 80px !important;
//   }
//   .video-react-progress-control.video-react-control{
//     position: relative;
//     right: 7%;
//   }
//   .video-react-duration.video-react-time-control.video-react-control {
//     position: relative;
//     left: 64%;
//   }
//   .video-react-current-time.video-react-time-control.video-react-control{
//     position: relative;
//   }
//   .video-react-play-progress.video-react-slider-bar {
//     background-color: $primary-color;
//   }
//   .video-react-play-progress:before {
//     top: -8px !important;
//     font-size: 20px !important;
//     color: $primary-color;
//   }
//   .video-react.video-react-fluid,.video-react-video{
//     border-radius: 10px;
//   }
//   .video-react-playback-rate.video-react-menu-button-popup.video-react-control.video-react-button.video-react-menu-button {
//     font-size: 14px;
//     bottom: 5px;
//   }
// }
// @media only screen and (max-width: 1024px) {
//   .video-wrapper{
//     button.video-react-play-control.video-react-control.video-react-button.video-react-playing,
//     button.video-react-play-control.video-react-control.video-react-button.video-react-paused,
//     button.video-react-control.video-react-button.video-react-icon.video-react-icon-replay-10.video-react-replay-control,
//     button.video-react-control.video-react-button.video-react-icon.video-react-icon-forward-10.video-react-forward-control
//     {
//       position: absolute;
//       left: 0;
//       right: 18%;
//       margin: auto;
//       top: -22vh;
//       font-size: 40px;
//     }
//     button.video-react-control.video-react-button.video-react-icon.video-react-icon-replay-10.video-react-replay-control,
//     button.video-react-control.video-react-button.video-react-icon.video-react-icon-forward-10.video-react-forward-control
//     {
//       right: 45%;
//       font-size: 25px;
//       top: -20vh;
//     }
//     button.video-react-control.video-react-button.video-react-icon.video-react-icon-forward-10.video-react-forward-control{
//       left: 5%;
//       right: 0;
//     }
//     .video-react-control-bar.video-react-control-bar-auto-hide {
//       background: transparent;
//       width: 750px;
//       margin: 0 auto 30px auto;
//     }
//     button.video-react-icon-fullscreen.video-react-fullscreen-control.video-react-control.video-react-button.video-react-icon,
//     button.video-react-icon-fullscreen-exit.video-react-fullscreen-control.video-react-control.video-react-button.video-react-icon
//     {
//       font-size: 15px;
//       bottom: 7px
//     }
//     .video-react-current-time.video-react-time-control.video-react-control,
//     .video-react-duration.video-react-time-control.video-react-control
//     {
//       font-size: 14px;
//       bottom: 5px;
//     }
//     .video-react-time-control.video-react-time-divider{
//       font-size: 16px;
//       bottom: 15px;
//       display: none;
//     }
//     button.video-react-big-play-button.video-react-big-play-button-left{
//       position: absolute;
//       border: unset;
//       background: transparent;
//       top:0;
//       bottom: 0;
//       left: 0;
//       right: 0;
//       margin: auto;
//       font-size: 80px !important;
//     }
//     .video-react-progress-control.video-react-control{
//       position: relative;
//       right: 7%;
//     }
//     .video-react-duration.video-react-time-control.video-react-control {
//       position: relative;
//       left: 64%;
//     }
//     .video-react-current-time.video-react-time-control.video-react-control{
//       position: relative;
//     }
//     .video-react-play-progress.video-react-slider-bar {
//       background-color: $primary-color;
//     }
//     .video-react-play-progress:before {
//       top: -8px !important;
//       font-size: 20px !important;
//       color: $primary-color;
//     }
//     .video-react.video-react-fluid,.video-react-video{
//       border-radius: 10px;
//     }
//     .video-react-playback-rate.video-react-menu-button-popup.video-react-control.video-react-button.video-react-menu-button {
//       font-size: 14px;
//       bottom: 5px;
//     }
//   }
// }

.video-container {
  position: relative;
}

video {
  width: 100%;
  height: 100%;
}

video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

.fullscreen-button {
  position: absolute;
  top: 2%;
  right: 2%;
  z-index: 10;
}

.watermark {
  position: absolute;
  left: 85%;
  bottom: 15%;
  opacity: 0.3;
  font-weight: 700;
  font-size: 24px;
  max-width: 70%;

  // position: absolute;
  // left: 65%;
  // top: 40%;
  // opacity: 0.5;
  // font-weight: 700;
  // font-size: 24px;
  img {
    // width: 150px;
    // width: 100px;
    // height: 100px;
    max-width: inherit;
    height: auto;
  }

  p {
    margin: 0;
    text-align: center;
    color: white;
    pointer-events: none;
  }
}

@keyframes moving-watermark {
  0% {
    left: 3%;
    top: 3%;
  }

  25% {
    left: 93%;
    top: 3%;
  }

  50% {
    left: 93%;
    top: 93%;
  }

  75% {
    left: 3%;
    top: 93%;
  }

  100% {
    left: 3%;
    top: 3%;
  }
}

.marquee {
  height: 80%;
  width: 100%;
  position: absolute;
  top: 0;
  opacity: 0.3;
}

.marquee div {
  position: absolute;
 animation: myfirst 20s linear infinite;
  animation-direction: alternate-reverse;
}

.marquee p{
  color: white;
  margin-bottom:0
}

@keyframes myfirst {
  0%   {left: 0px; top: 0px;}
  25%  {left: 100%; top: 50px;}
  50%  { left: 50%; top: 100%;}
  75%  { left: 50px; top: 100%;}
  100% { left: 0px; top: 0px;}
}

.player-wrapper {
    width: 128%;
    height: 100%;
    background: #16102b;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .video-container {
    position: unset;
  }
  
  .react-player {
    position: unset;
  }

  .marquee {
    height: 14%;

    div {
      font-size: x-small;
    }
  }

  .watermark {
    bottom: unset; 
    top: 14%;
  }


  .fullscreen-button {
    position: absolute;
    top: 8px;
    right: 5px;
  }

  .player-wrapper {
    background: #16102b;
  }

  .video-content {
    .ant-divider-horizontal, .ant-typography-ellipsis {
      display: none;
    }
  }
}
