.announcement-class {
    .announcement-class-announcement-now {
        padding: 1rem 2rem 2rem;
        h3 {
            color: #fff;
            font-weight: 600;
        }
    }
    .announcement-class-upcoming-classes {
        padding: 1rem 2rem 2rem;
        h3 {
            font-weight: 600;
        }
    }
    .no-announcement {
        h3 {
            color: #fff;
        }
    }
}