.instruction__wrapper{
    img {
        // margin: auto;
        display: flex;
        text-align: center;
        margin: auto;
    }

    .instructions {
        margin-top: 40px;
        h2 {
            font-weight: 600;
        }
    
        ul {
            margin-top: 15px; 
    
            li {
                margin: 14px 0px;
                font-size: 16px;
                color: #7E899E;
            }
        }
    }
}