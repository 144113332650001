.analytics__card {
  padding: 3rem 0;
  // margin-top: 1rem;
  img {
    width: 30px;
    height: 30px;
  }
  .analytics__course {
    font-size: 32px;
    color: white;
    margin-bottom: 10px;
    text-align: center;
  }
  .analytics__metric {
    display: flex;
    img {
      margin-top: 5%;
      margin-right: 5%;
    }
  }
  .analytics__rank {
    font-size: 16px;
    text-align: center;
    width: 60%;
    margin-top: 5px;
    color: white;
    background: transparent linear-gradient(90deg, #f7971e 0%, #ffd200 100%) 0%
      0% no-repeat padding-box;
    border-radius: 16px;
    padding: 5px;
  }
  .analytics-measure__value {
    font-size: 32px;
    font-weight: 600;
    color: white;
    margin: 0;

    span {
      font-size: 14px;
    }
  }
  .analytics__measure {
    font-size: 14px;
    color: white;
    margin: 0;
  }
  .progress__metric {
    margin-left: 10%;
    margin-top: 2%;
  }
  .progress__container {
    display: flex;
    .progress__title {
      color: #ffffff;
      opacity: 0.8;
      font-size: 14px;
      width: 35%;
    }
    .progress__wrapper {
      margin-left: 10%;
      display: flex;
      width: 50%;
      .progress__score {
        margin-right: 10%;

        span {
          font-weight: 600;
        }
        font-size: 14px;
        color: #ffffff;
        opacity: 1;
      }
    }
  }

  .ant-progress-text {
    color: white !important;
  }
}
@media (min-width: 1200px) and (max-width: 1599px) {
  .analytics__card {
    .analytics-measure__value {
      font-size: 20px !important;
      font-weight: 600;
      color: white;
      margin-bottom: 0.2rem !important;

      span {
        font-size: 12px !important;
      }
    }
    .analytics__measure {
      font-size: 12px !important;
      color: white;
      margin: 0;
    }
    .progress__container {
      display: flex;
      align-items: center !important;
      .progress__title {
        color: #ffffff;
        opacity: 0.8;
        font-size: 10px !important;
        width: 40% !important;
        margin-bottom: 1rem !important;
      }
      .progress__wrapper {
        margin-left: 10%;
        display: flex;
        width: 50%;
        .progress__score {
          margin-right: 10%;

          span {
            font-weight: 600;
          }
          font-size: 14px;
          color: #ffffff;
          opacity: 1;
        }
      }
    }
   
  }
  .subjects-details__container {
    width: 80% !important;
  }
}
