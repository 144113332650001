.exercises__wrapper {
  height: 100%;
  .content-left__wrapper {
    overflow: auto;
    height: 100%;
    // -ms-overflow-style: none;
    // scrollbar-width: none;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }
  .subject__card {
    padding: 2rem 3rem;
    p {
      font-size: 16px;
      font-weight: 500;
    }

    .subjects-details__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 50%;
      p {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        color: #54c1e3;
      }
      .progress__wrapper {
        .ant-progress {
          width: 30%;
        }
        display: flex;
        width: 60%;
        p {
          color: #56c2b8;
          margin: 0;
          margin-left: 10%;
        }
      }
    }
  }
  .subject-card__active {
    background-color: #f4f6f8;
    border-left: 3px solid rgb(187, 187, 187);
    p {
      color: #0c0137;
    }
    .subjects-details__container {
      p {
        color: #56c2b8;
      }
      .progress__wrapper {
        p {
          color: #0c0137;
        }
      }
    }
  }

.content__right {
  height: 100%;
  overflow: auto;
  padding: 0 8% 5% 8%;
  margin-top: -3rem;
  // -ms-overflow-style: none;
  // scrollbar-width: none;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  .content-right__header {
    padding: 3rem 3rem 0 3rem;
    display: flex;
    position: sticky;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 20px;
      font-weight: 600;
      margin: 0;
    }
    .content-right__btn {
      border: 1px solid #5839cb;
      padding: 2px 5px;
      border-radius: 10px;
      font-size: 12px;
      width: fit-content;
      color: #5839cb;
      cursor: pointer;
      span {
        font-size: 9px;
      }
    }
  }
}

}