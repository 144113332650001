@import "src/styles/variables";

.building-content__container {
  min-height: 100vh;
  background: transparent linear-gradient(180deg, #5839cb 0%, #32169b 100%) 0% 0% no-repeat padding-box;

  text-align: center;
  padding: 5%;

  p {
    margin-top: 8%;
    color: $white-color;
    font-weight: 600;
    font-size: 16px;
  }
  .already-have-acc{
    margin-top: 2%;
  }

  .app__icon {
    img {
      width: 300px;
      height: 100px;
    }
  }

  .pencil__icon {
    margin-top: 2%;
    position: relative;

    img {
      width: 10%;
      height: 5%;
    }

    .red__circle {
      position: absolute;
      background: transparent linear-gradient(90deg, #d75878 0%, #f3577d 100%) 0% 0% no-repeat padding-box;
      opacity: 0.3;
      width: 100px;
      height: 100px;
      border-radius: 50px;
      right: 41%;
      top: 58%;
    }
  }
}