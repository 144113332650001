.analytics__component {
    .attempts__dropdown {
        margin-left: 35px;
        .dropdown-field .ant-select {
            width: 50%;
            .ant-select-selector {
                box-shadow: none !important;
            }
        }
    }
    .divider__line {
        margin: 50px auto !important;
    }
    .measures__card {
        border-radius: 10px;
        width: 100%;
        height: 105px;
        margin-bottom: 15px;
        text-align: center;
        position: relative;
        p:first-child {
            margin: 0;
        }
        div {
            position: absolute;
            bottom: 10px;
            width: 100%;
        }
        img {
            position: absolute;
            top: -30%;
            right: 40%;
            left: 40%;
        }
        .measure__value {
            font-size: 24px;
            font-weight: 600;
            margin: 0;
        }
        .measure__title {
            font-size: 14px;
            margin: 0;
        }
    }
    .accuracy {
        background: rgba(208, 40, 44, 0.1);
        color: rgba(208, 40, 44, 1);
        .measure__value,
        .measure__title {
            color: rgba(208, 40, 44, 1);
        }
    }
    .score {
        background: rgba(29, 123, 210, 0.1);
        color: rgba(29, 123, 210, 1);
        .measure__value,
        .measure__title {
            color: rgba(29, 123, 210, 1);
        }
    }
    .speed {
        background: rgba(255, 119, 33, 0.1);
        .measure__value,
        .measure__title {
            color: rgba(255, 119, 33, 1);
        }
    }
    .duration {
        background: rgba(85, 139, 88, 0.1);
        .measure__value,
        .measure__title {
            color: rgba(85, 139, 88, 1);
        }
    }
    .measure-cards__container {
        margin: 0 15%;
        .measure-cards__wrapper {
            margin-top: 10%;
        }
    }
    .measure-card-no-attempt--padding {
        padding-top: 15%;
    }
    .rank__container {
        background: url('../../../../assets/icon/cloud.svg');
        background-repeat: no-repeat;
        background-position-x: 50%;
        background-size: 60%;
        height: 150px;
        width: 100%;
        display: block;
        margin: auto;
        position: relative;
        margin-top: 5%;
        .rank-picture__container {
            text-align: center;
            img {
                width: 150px;
                height: 150px;
                border-radius: 75px;
            }
            .rank {
                position: absolute;
                top: 85%;
                left: 40%;
                background: transparent
                    linear-gradient(
                        90deg,
                        rgba(247, 151, 30, 1) 0%,
                        rgba(255, 210, 0, 1) 100%
                    )
                    0% 0% no-repeat padding-box;

                border-radius: 16px;
                padding: 5px 0;
                color: #fff;
                font-weight: bold;
                font-size: 16px;
                width: 20%;
                display: block;
                margin: auto;
                text-align: center;
            }
        }
    }
    .rank__text {
        text-align: center;
        color: #7e899e;
        margin-top: 1rem;
    }
    .progress__container {
        margin: 15% 15% 10% 15%;

        .progress-content__learnt {
            background-color: rgba(237, 76, 138, 0.25);
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 10px;
            padding: 1rem;
            p {
                margin: 0;
            }
            .progress-content__left {
                width: 50%;
                .progress__value {
                    font-size: 24px;
                    font-weight: 600;
                    color: #ed4c8a;

                    span {
                        font-size: 14px;
                    }
                }
                .progress__desc {
                    color: #ed4c8a;
                }
            }
            .progress-content__right {
                width: 45px;
                height: 55px;
            }
        }
    }
    .exercise-progress__container {
        margin-top: 5%;
        .progress__title {
            font-size: 14px;
            span {
                img {
                    width: 10px;
                    height: 10px;
                }
            }
        }
        .progress-exercise__attempted {
            background-color: rgba(126, 72, 171, 0.25);
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 10px;
            padding: 1rem;

            p {
                margin: 0;
            }
            .progress-content__left {
                width: 50%;
                .progress__value {
                    font-size: 24px;
                    font-weight: 600;
                    color: #7e48ab;

                    span {
                        font-size: 14px;
                    }
                }
                .progress__desc {
                    color: #7e48ab;
                }
            }
            .progress-content__right {
                width: 45px;
                height: 55px;
            }
        }
    }
    .analytics-progress__title {
        font-size: 14px;
        span {
            img {
                margin-left: 3px;
                margin-top: -3px;
                width: 18px;
                height: 18px;
            }
        }
    }
}
@media (min-width: 1200px) and (max-width: 1599px) {
    .measures__card {
        img {
            position: absolute;
            top: -30%;
            right: 45% !important;
            left: 30% !important;
        }
    }
    .measure__value {
        font-size: 18px !important;
        font-weight: 600;
        margin: 0;
    }
    .measure__title {
        font-size: 12px !important;
        margin: 0;
    }
}

@media only screen and (max-width: 768px) {
    .analytics__component {
        margin-bottom: 7rem;
        .measure-cards__container {
            margin: 0 3%;
        }

        .rank__container {
            margin-top: 2%;
            height: unset;
            .rank-picture__container {
                img {
                    width: 100px;
                    height: 100px;
                    border-radius: 75px;
                }
            }
        }

        .progress__container {
            margin: 15px 3%;

            .progress-content__learnt {
                padding: 2px 1rem;
            }
        }

        .measures__card {
            height: 95px;
            margin-bottom: unset;

            img {
                top: -25%;
            }
        }

        .measures__card {
            .measure__value {
                font-size: 14px;
            }
            .measure__title {
                font-size: 14px;
                margin: 0;
            }
        }

        .exercise-progress__container {
            margin-top: 2%;

            .progress-exercise__attempted {
                padding: 2px 1rem;
            }
        }
    }
}
