@import "src/styles/variables";

.login-form__container {
	margin-top: 3rem;
	h2 {
		color: white;
		font-weight: bolder;
		text-align: left;
	}

	.btn__wrapper {
		margin-top: 1rem;
	}

	.about__mindjjo {
    margin: 10% 0 10% 0;
    h1,
    p {
      color: white;
    }
  }

	.dont-have-acc {
		color: white;
		font-size: 14px;
		margin-top: 1rem;
		span {
			color: $secondary-color;
			font-weight: bold;
			cursor: pointer;
		}

		text-align: center;
	}
	.terms-and-conditions {
		font-size: 12px;
		color: white;
		text-align: center;
		margin-top: 0.5rem;
		span {
			color: $secondary-color;
			cursor: pointer;
		}
	}
	.login-form__forgot-password {
		color: white;
		text-align: center;
		margin-top: 1rem;
		span {
			color: $secondary-color;
			cursor: pointer;
		}
	}
}
