.class-board__card {
  background: #11AC84 !important;
  border: 1px solid transparent;
  border-radius: 10px !important;
  padding: 0px !important;
  height: 45px;
  width: 100px;
  color: white !important;
  margin: 5px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
    font-size: 100%;
    font-weight: 600;
    color: white;
  }
}
.card__border {
  border: 2px solid white !important;
}
.classes-form__container {
  margin-top: 5rem;

  h1 {
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-align: start;
    span {
      margin-right: 2%;
    }
  }
  color: white;
  .btn__wrapper {
    margin-top: 0.5rem;
  }
  .classes__wrapper {
    max-height: 35vh;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 1rem;
    padding: 5px 0px !important;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 10px;
      &:hover {
        background: rgba(255, 255, 255, 0.4);
      }
    }
  }
}
