.notifications-modal__container {
    top: 0 !important;
    right: -16% !important;
    .ant-modal-content {
      border-radius: 0 !important;
    }
    .ant-modal-body {
      padding: 0px !important;
      padding-bottom: 1% !important;
    }
    .notifications-modal__title {
        display: flex;
        justify-content: space-between;
        margin-right: 10%;
        p {
            font-size: 18px;
            font-weight: 600;
            margin: 0;
        }
        .clear__all {
            font-size: 14px;
            font-weight: 400;
            color: #7E899E;
        }
    }
    .notifications-list__wrapper {
      height: 78vh;
      overflow-y: auto;
    }
}