.step-pencil__container {
  margin: 3% 0;
  width: 100%;
  display: flex;
  .step-pencil__body {
    height: 36px;
    background-color: #5839cb;
    border-radius: 18px 0px 0px 17px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 500;
    .filled {
      background: transparent linear-gradient(112deg, #d75878 0%, #f3577d 100%)
        0% 0% no-repeat padding-box;
      height: 100%;
    }
  }
  .step-pencil__neck {
    border-top: 10px solid transparent;
    border-left: 20px solid rgba(88, 57, 203, 0.5);
    border-bottom: 10px solid transparent;
  }
  .step-pencil__hook {
    height: 8px;
    width: 2%;
    margin-top: 10px;
    border-top: 8px solid transparent;
    border-left: 14px solid rgba(88, 57, 203, 1);
    border-bottom: 8px solid transparent;
  }
}
