.exam-pannel__container {
    height: 100vh;
    width: 100vw;
    background-color: #051739;
    display: flex;
    justify-content: center;
    align-items: center;

    .content__wrapper {
        overflow-y: auto;
        height: 85%;
    }

    .close-wrapper {
        position: absolute;
        top: 2rem;
        right: 3rem;
        color: white;
        display: flex;
        cursor: pointer;

        img {
            height: 14px;
            margin: auto;
        }

        span {
            opacity: 0;
            transition: opacity 0.5s, padding-left 0.5s;
        }

        &:hover {
            span {
                opacity: 1;
                padding-left: 12px;
            }
        }
    }

    .exam-card {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 20px 40px #0f47b24d;
        border-radius: 10px;
        color: black;
        padding: 30px 0;
        width: 70%;
        height: 80vh;
        transition: width 0.7s, height 0.7s, margin-left 0.7s;

        // .finish-question {
        // 	opacity: 1;
        // 	transition: opacity 0.5s;
        // 	width: 50px !important;
        // 	height: 10px !important;
        // }
        .retake-question {
            opacity: 1;
            transition: opacity 0.5s;
            width: 75px;
            height: 30px;
            background: transparent
                linear-gradient(17deg, #3475f6 0%, #1b5ee0 100%) 0% 0% no-repeat
                padding-box;
            text-align: center;
            color: white;
            border-radius: 10px;
            font-weight: 600;
            padding: 5px 0;
            cursor: pointer;
        }

        .finish-question {
            opacity: 1;
            transition: opacity 0.5s;

            height: 30px;
            background: transparent
                linear-gradient(17deg, #3475f6 0%, #1b5ee0 100%) 0% 0% no-repeat
                padding-box;
            text-align: center;
            color: white;
            border-radius: 10px;
            font-weight: 600;
            padding: 4px 15px;
            cursor: pointer;
            margin-left: 25%;
        }

        // .finish-question-show {
        // 	p {
        // 		padding-right: 15px;
        // 	}
        // 	.finish-question {
        // 		opacity: 1;
        // 		transition: opacity 0.5s;
        // 		width: 50px;
        // 	height: 30px;
        // 	}
        // }

        .timer {
            display: flex;

            p {
                width: 100%;
                height: 100%;
                margin: auto 0 auto auto;
                color: #7e899e;
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                transition: padding-right 0.5s;

                img {
                    height: 15px;
                    width: 15px;
                    margin-right: 10px;
                }
            }
        }

        .question-title {
            p {
                font-size: 18px;
                font-weight: bolder;
                margin: auto 0 5px;
            }
        }

        .question {
            color: #2b2b2b;
            font-weight: 500;
            font-size: 16px;
            margin-top: 30px;
        }

        .options-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 25px;
            margin-top: 30px;
        }
    }

    .exam-card__srink {
        width: 50% !important;
        height: 70% !important;
        margin-left: 30vw;

        .options-wrapper {
            .option__container {
                padding: 10px;

                .option {
                    p {
                        padding-left: 15px;
                    }
                }
            }
        }
    }

    .solution-wrapper {
        margin-top: 2.5rem;
        padding: 20px;
        background: #f7fff7 0% 0% no-repeat padding-box;
        border: 0.5px solid #108e10;
        border-radius: 10px;
        position: relative;

        .solution-heading {
            color: #108e10;
            font-weight: bold;
            font-size: 16px;
            position: absolute;
            top: -12px;
            background-color: white;
            padding: 0px 5px;

            span {
                margin: 0 3px;
            }
        }

        // .solution {
        // }
    }

    .comment-wrapper {
        margin-top: 2.5rem;
        padding: 20px;
        background: #f7fff7 0% 0% no-repeat padding-box;
        border: 0.5px solid #0e7ee0;
        border-radius: 10px;
        position: relative;

        .comment-heading {
            color: #0e7ee0;
            font-weight: bold;
            font-size: 16px;
            position: absolute;
            top: -12px;
            background-color: white;
            padding: 0px 5px;

            span {
                margin: 0 3px;
            }
        }

        // .comment {
        // }
    }

    .correct-answer-wrapper {
        margin-top: 2.5rem;
        padding: 20px;
        background: #f7fff7 0% 0% no-repeat padding-box;
        border: 0.5px solid #108e10;
        border-radius: 10px;
        position: relative;

        .correct-answer-heading {
            color: #108e10;
            font-weight: bold;
            font-size: 16px;
            position: absolute;
            top: -12px;
            background-color: white;
            padding: 0px 5px;

            span {
                margin: 0 3px;
            }
        }

        // .correct-answer {
        // }
    }

    .incorrect-answer-wrapper {
        margin-top: 2.5rem;
        padding: 20px;
        background: #f7fff7 0% 0% no-repeat padding-box;
        border: 0.5px solid #dc1313;
        border-radius: 10px;
        position: relative;

        .incorrect-answer-heading {
            color: #dc1313;
            font-weight: bold;
            font-size: 16px;
            position: absolute;
            top: -12px;
            background-color: white;
            padding: 0px 5px;

            span {
                margin: 0 3px;
            }
        }

        // .incorrect-answer {
        // }
    }
}

.prespecitve-modal {
    .ant-modal-body {
        text-align: center;

        // img {
        // }
        h2 {
            font-weight: bold;
            margin-top: 1rem;
        }

        .remaning {
            font-weight: bold;
            margin-top: 0.7em;
        }
    }
}

.quiz-free-text-input {
    max-width: unset !important;
    width: 100% !important;
    border-radius: 5px !important;
}

.pending-answer-note {
    text-align: center;
    margin-top: 15px;
}

.question-make__review {
    margin-left: 15px;

    p {
        color: gray;
        font-size: 15px;
        cursor: pointer;
        margin-left: 10px;
    }

    span {
        margin-right: 5px;
    }
}

.question-marked__review {
    margin-left: 15px;
    color: #ff9810;

    span {
        margin-right: 5px;
    }

    p {
        color: #ff9810;
        font-size: 15px;
        cursor: pointer;
    }
}

.attachment__image {
    width: 15vw;
    height: 15vh;
}

.horizontal-padding {
    padding: 0 30px;
}

@media (min-width: 1200px) and (max-width: 1599px) {
    .exam-pannel__container {
        .content__wrapper {
            overflow-y: auto;
            height: 70% !important;
            margin-bottom: 2rem;
        }
    }
}

@media only screen and (min-width: 769px) {
    .exam-pannel__container {
        .exam-card {
            .exam__board {
                height: 85%;
                .content__wrapper {
                    overflow-y: auto;
                    height: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .exam-pannel__container {
        align-items: unset;
        height: calc(100vh - 54px);

        .solution-wrapper {
            margin-bottom: 5px;
        }

        .horizontal-padding {
            padding: 0 7px;
            align-items: flex-start;
        }

        .exam-card {
            width: 100%;
            margin-top: 4vh;
            height: calc(100vh - 80px);

            .question-title p {
                font-size: 11px;
            }

            .question-make__review p {
                font-size: 11px;
            }

            .exam__board {
                overflow: scroll;
                height: calc(96vh - 85px);
                padding-bottom: 4.5rem;
            }
            .options-wrapper {
                display: block;
                margin-top: 0px;
            }

            .finish-question {
                position: fixed;
                top: 5px;
                right: 70px;
                height: 30px;
                background: unset;
            }
        }

        .close-wrapper {
            position: fixed;
            display: unset;
            width: 20%;
            left: 15px;
            top: 5px;
        }
    }

    .questions-drawer__container {
        .fixed-opener {
            position: fixed;

            top: 2px !important;
            right: 15px;
            img {
                background-color: unset !important;
                width: 35px !important;
            }
        }
    }
}
