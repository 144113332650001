@import 'src/styles/variables';

.subject-card-expand__container {
    background-color: white;
    border-radius: 10px;
    margin-bottom: 1rem;
    padding: 24px 20px;
    box-shadow: 2px 2px 10px #0f47b20f;
    width: 96%;
    border: 1px solid #fd9000;
    margin: 0 auto 1rem;
    transition: width 0.5s;
    cursor: pointer;
    &:hover {
        width: 100% !important;
    }

    .ant-col.ant-col-2 {
        h2 {
            text-align: center;
            color: #bec4ce;
        }
    }
    .subject-card__index {
        color: #bec4ce;
    }
    .ant-col.ant-col-22 {
        p {
            color: $text-color;
            font-size: 20px;
            font-weight: 600;
        }
    }
    .chapter__name {
        color: $text-color;
        font-size: 20px;
        font-weight: 600;
    }

    .dot::before {
        content: ' ';
        color: red;
        background-color: red;
        border-radius: 100px;
    }
}

.active-subject-card {
    border: 1px solid #fd9000;
    background-color: #fd9000;

    .dull-text {
        color: white !important;
        font-weight: lighter !important;
    }
    .dull-text-completion {
        font-size: 14px !important;
        color: white !important;
        font-weight: lighter !important;
    }

    .ant-col.ant-col-22 {
        p {
            color: white;
        }
    }
    .chapter__name {
        color: white;
    }
    .ant-col.ant-col-2 {
        h2 {
            color: #fd9000;
            opacity: 0.4;
        }
    }
}

.dull-text {
    color: #54c1e3 !important;
    font-weight: lighter !important;
}
.dull-text-completion {
    color: #56c2b8 !important;
    font-weight: lighter !important;
    font-size: 14px !important;
}

@media only screen and (max-width: 768px) {
    .subject-card-expand__container {
        padding: 15px;
        border: 0;
    }
}
