@import "src/styles/variables";

.delete-alert__modal{
  width: 30% !important;
  .ant-modal-content{
    border-radius: 10px;
    text-align: center;
  }
}

.delete-alert__icon{
  width: 100%;
  margin-top: 20px;
  img{
    width: 20%;
  }
}

.delete-alert__text{
  color: $label-color;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10%;
  span{
    text-transform: capitalize;
  }
}

.delete-alert__warning{
  color: $placeholder-color;
  font-size: 12px;
  margin-bottom: 10%;
}

.delete-alert__logo{
  img{
    width: 20%;
  }
}

.delete-alert__thumbnail{
  margin-bottom: 5px;
  img{
    width: 50%;
    border-radius: 10px;
  }
}

.delete-alert__title{
  color: $label-color;
  font-size: 18px;
  font-weight: 600;
  margin-top: 5px;
}

.delete-content__title{
  color: #545454;
  font-size: 14px;
  font-weight: 600;
}

.delete-alert__detail{
  color: $label-color;
  font-size: 12px;
  margin-top: 5px;
}

.delete-content__detail{
  color: $placeholder-color;
  margin-top: 0;
}

.delete-alert__action{
  margin: 10% auto 0 auto;
  width: 70%;
}

.delete-alert__button{
  display: inline-block;
  vertical-align: top;
  width: 48%;
  &:first-child{
    margin-right: 4%;
  }
  .ant-btn-primary{
    background: $delete-color !important;
  }
}

.blacklist-reason{
  text-align: left;
  margin-top: 15px;
  .label{
    font-size: 12px;
    margin-bottom: 5px;
  }
  input{
    background: #f8f8fa;
    width: 100%;
    outline: unset;
    border: unset;
    height: 40px;
    border-radius: 10px;
    padding-left: 10px;
  }
}
