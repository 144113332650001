@import "src/styles/variables";

.rank-board__container {
  display: flex;
  width: 40%;
  height: 140px;
  justify-content: center;
  margin: 3rem auto 0;
  text-align: center;
  .student-image__container {
    position: relative;
    .student__image {
      width: 80px;
      height: 80px;
      border-radius: 50px;
    }
    .student__rank {
      position: absolute;
      width: 22px;
      height: 22px;
      border-radius: 20px;
      z-index: 10;
      left: 39%;
      top: 85%;
    }
  }
  .student__name {
    color: white;
    margin-top: 1.2rem;
    margin-bottom: 0.2rem;
    font-size: 14px;
  }
  .student__point {
    font-weight: 700;
    font-style: italic;
    font-size: 13px;
  }
  .rank-board-1 {
    margin-top: -4rem;
    margin-left: 4rem;
    margin-right: 4rem;
    .crown__icon {
        margin-bottom: 0.5rem;
    }
    .student__image {
      box-shadow: 0px 10px 20px #ffd44280;
      border: 2px solid #ffd442;
      width: 90px;
      height: 90px;
    }
    .student__rank {
      background-color: #ffd442;
    }
    .student__point {
      color: #ffd442;
    }
  }
  .rank-board-2 {
    .student__image {
      box-shadow: 0px 10px 20px #4feaff80;
      border: 2px solid #4feaff;
    }
    .student__rank {
      background-color: #4feaff;
    }
    .student__point {
      color: #4feaff;
    }
  }
  .rank-board-3 {
    .student__image {
      box-shadow: 0px 10px 20px #ff9efb80;
      border: 2px solid #ff9efb;
    }
    .student__rank {
      background-color: #ff9efb;
    }
    .student__point {
      color: #ff9efb;
    }
  }
}

@media only screen and (max-width: 768px) {
  .rank-board__container {
    position: relative;
    top: 110px;
  }
}
