.list-courses__wrapper {
  .list-courses__container {
    border-radius: 20px;
    margin-top: 1rem;
    padding: 1.5rem 2rem;
    background-color: white;
    display: flex;
    align-items: center;
    box-shadow: 2px 2px 10px #0f47b20f;
    .list-courses__dp {
      img {
        width: 75px;
        height: 75px;
        object-fit: cover;
      }
    }
    .list-courses__details {
      width: 70%;
      margin-left: 4%;
      p {
        color: #0c1013;
        margin: 0;
      }
      .list-courses-details__acronym {
        font-weight: bold;
        font-size: 18px;
      }
      .list-courses-details__name {
        font-size: 14px;
      }
    }
    .list-courses__menu {
      position: absolute;
      right: 5%;
      top: 30%;
      visibility: hidden;
      span {
        font-size: 20px;
      }
    }
    &:hover {
      .list-courses__menu {
        visibility: visible;
      }
    }
  }
}
.list-courses-edit__menu {
  background: #051739 !important;
  padding: 0 !important;
  border-radius: 10px !important;
}
.list-courses-edit__item {
  padding: 1rem 1.5rem 0.2rem 1.5rem !important;
  background: #051739 !important;
  margin: 0 !important;
  color: white !important;
  border-radius: 10px !important;
}
.edit__icon {
  width: 15px;
  height: 15px;
  margin-right: 0.7rem;
}
.list-courses-add__course {
  background: rgba(126, 137, 158, 0.2);
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  color: #7e899e;
  font-size: 18px;
  margin-top: 1rem;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin: 0;
  }
}
.course-heading__wrapper {
  margin: 3% 1% 0 1%;
  display: flex;
  justify-content: space-between;
  .course__heading {
    font-weight: 600;
    font-size: 18px;
  }
  .subscribe-new__exam {
    color: #5839cb;
    font-size: 16px;
    font-weight: 500;
  }
}
.my-account__heading {
  margin: 3% 1% 1% 1%;
  font-weight: 600;
  font-size: 18px;
}
.my-account__container {
  border-radius: 20px;
  margin-top: 1rem;
  padding: 2rem 2rem 1rem 2rem;
  background-color: white;
  box-shadow: 2px 2px 10px #0f47b20f;
.my-account-details__image {
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 15px;
  background-color: #eeebfa;
  margin-right: 2%;
  img {
    margin-top: 5px;
    width: 15px;
    height: 15px;
  }
}
.my-account-details__wrapper {
  display: flex;
  width: 70%;
}
  .my-account__details {
    font-size: 20px;
    font-weight: 500;

  }
  .my-account-phone__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1%;
    .my-account-image__wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .phone__change {
      font-size: 14px;
      color: #5839CB;
      cursor: pointer;
    }
  }
  .edit__email {
    margin-left: 4%;
    font-size: 20px;
    font-weight: 500;
    color: #0C0137;
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
    border-bottom: 1px solid #5839CB !important;
  }
}
