.notification-card__container {
  background: #ffffff;
  box-shadow: 2px 2px 15px #0f47b21a;
  border: 1px solid #f5f6fa;
  border-radius: 10px;
  margin: 1rem;
  padding: 1rem;
  position: relative;
  cursor: pointer;
 
  &:hover {
    .close__icon {
      display: block;
    }
  }
  .notification__title {
    p {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  .notification-card__type {
    display: flex;
    justify-content: space-between;
    p {
      font-style: italic;
      color: #ea577b;
      // text-transform: capitalize;
      font-weight: 300;
      font-size: 12px;
    }
  }
  
  .notification__video {
    p {
      color: #ea577b;
    }
  }
  .notification__pdf {
    p {
      color: #4c9cec;
    }
  }
  .notification__admin {
    p {
      color: #a773fd;
    }
  }
  .notification__tutor {
    p {
      color: #4cc45b;
    }
  }
  .notification__exercise {
    p {
      color: #a773fd;
    }
  }
  .notification__read {
    p {
     color: #a19f9f !important;
    }
  }
  .notification-card-image__wrapper {
    display: flex;
    justify-content: space-between;

    p {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      margin-right: 5%;
    }
    img {
      width: 40px;
      height: 40px;
      border-radius: 8px;
    }
  }
  .notification__details {
    p {
      font-size: 13px;
      font-weight: 400;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      margin-right: 5%;
    }
    .notification__title {
      font-size: 15px;
      font-weight: 600;
      span {
        font-weight: 300;
      }
    }
  }
  .close__icon {
    position: absolute;
    display: none;
    top: 3%;
    right: 0;
    background: transparent
      linear-gradient(270deg, #ffffff 0%, #ffffffaf 83%, #ffffff00 100%) 0% 0%
      no-repeat padding-box;
    padding: 0.5rem 1rem;
    border-bottom-left-radius: 0.5rem;
  }
  .type__title {
    width: 70%;
  }
  .type__icon {
    margin-right: 3%;
  }
  .notification__type {
    text-transform: lowercase !important;
  }
}
