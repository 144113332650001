@import "src/styles/variables";

.tac__container {
  background: #f5f8fa;
  height: 100vh;
  padding: 2rem;

  h1 {
    font-weight: bold;
    font-size: 40px;
  }
  // .tac__header {
  //   height: 20%;
  //   background-color: $secondary-color;
  //   position: relative;
  //   margin-bottom: 2rem;
  //   h1 {
  //     color: white;
  //     position: absolute;
  //     bottom: 2%;
  //     left: 2%;
  //     font-weight: bold;
  //   }
  // }
  // .tac__content {
  //   padding-right: 1%;
  //   margin-top: 2%;
  //   height: 70%;
  //   overflow-y: scroll;
  //   &::-webkit-scrollbar {
  //     width: 10px;
  //   }
  //   &::-webkit-scrollbar-track {
  //     background: transparent;
  //   }

  //   &::-webkit-scrollbar-thumb {
  //     background: rgba(0, 0, 0, 0.1);
  //     border-radius: 10px;
  //     &:hover {
  //       background: rgba(0, 0, 0, 0.3);
  //     }
  //   }
  //   p {
  //     text-indent: 5%;
  //     font-size: 15px;
  //     font-size: 16px;
  //   }
  // }
}
.tac__title {
  font-size: 26px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.tac__subtitle {
  font-weight: 700;
  margin: 0;
  font-size: 16px;
  margin-top: 0.5rem;
}
.tac__content {
  margin: 0;
  span {
    font-weight: bold;
  }
}
.tac__tab {
  margin: 1rem 2rem;
}
.tac__supersubtitle {
  font-weight: 700;
  margin: 0;
  font-size: 14px;
  margin-top: 0.5rem;
}