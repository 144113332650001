.loading__container {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 50px;
  position: relative;
  height: 100%;
  align-items: center;
  color: white;
  .ant-spin-spinning {
    color: white;
  }
  .ant-spin-text {
    font-weight: 300 !important;
    font-size: 13px;
    margin-top: 0.5rem;
  }
}
