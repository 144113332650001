@import "src/styles/variables";

.cartitem-card {
    background-color: #fff;
    padding: 0 10px;
    box-shadow: 2px 2px 10px #0f47b20f;
    border-radius: 10px;
    margin-bottom: 10px;
    // cursor: pointer;



    &.btn {
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition: 0.2s;

        &::before {
            content: '';
            height: 3px;
            width: 0;
            left: 0;
            bottom: 0;
            position: absolute;
            background: #5539CB;
            transition: 0.4s;
        }

        &:hover {
            &::before {
                width: 100%;
            }
        }

        &:active {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }


    &.live {
        border-left: 2px solid #5539CB;
    }

    .cartitem-card-date__container {
        // border-right: 1px solid #eeeeee60;
        padding: 10px 0;

        .cartitem-card__date {
            display: flex;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 0px;

            .__text {
                //display: inline-grid;
                display: flex;
                flex-direction: column;

                .__sub_title {
                    font-size: 16px;
                    font-weight: bold;
                    padding: 0px;
                    line-height: 1.2;
                    color: #56595f;
                    padding: 10px;
                }

                .__remove_text {
                    font-size: 12px;
                    padding: 0px;
                    line-height: 1.2;
                    color: #0c59f4;
                    cursor: pointer;
                    padding-left: 10px;
                }
            }



            img {
                width: 120px;
                height: 80px;
                margin-right: 10px;
                margin-bottom: 2px;
                border-radius: 10px;

            }
        }

        .cartitem-card__time {
            color: $primary-grey;
            font-weight: 500;
            margin-bottom: 0;

            .anticon {
                margin-right: 8px;
            }
        }
    }

    .cartitem-card-title__container {
        padding: 24px;

        .cartitem-card__title {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 12px;
        }

        .cartitem-card-more__container {
            display: flex;
            align-items: center;
            column-gap: 20px;

            p {
                margin-bottom: 0;
            }

            .cartitem-card__tutors {
                display: flex;
                min-width: 10%;

                img {
                    width: 20px;
                    height: 20px;
                    border-radius: 10px;
                    margin-left: -5px;
                    border: 1px solid #FFFFFF;

                    &:first-child {
                        margin-left: 0;
                    }
                }

                span {
                    color: $primary-grey;
                    font-weight: 500;
                    margin-right: 5px;
                }
            }

            .cartitem-card__subject {
                min-width: 15%;

                img {
                    width: 10px;
                    height: 13px;
                    margin-right: 5px;
                    margin-bottom: 2px;

                }

                font-weight: 500;
                color: $primary-grey;
            }

            .cartitem-card__class {
                img {
                    width: 12px;
                    height: 12px;
                    margin-right: 10px;
                    margin-bottom: 2px;
                }

                font-weight: 500;
                color: $primary-grey;
            }
        }
    }

    .cartitem-card__actions {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .cartitem-card__price {
        font-size: 16px;
        font-weight: bold;
        padding: 0px;
        line-height: 1.2;
        color: #56595f;

        span {
            font-weight: normal;
            margin-left: 5px;
            text-decoration: line-through;
            font-size: 12px;
            color: #7f8389;
        }
    }

}