.analytics-empty-screen__container {
    margin: auto;
    text-align: center;
  p {
    color: #a8b3ba;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    margin-top: 1rem;
  }
  .take-test-btn__container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5%;
    button {
      width: 25% !important;
    }
  }
}
