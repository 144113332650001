.page-header {
  width: 50%;
  margin-top: -3em;
  .heading {
    display: flex;

    h1 {
      margin: auto 0;
    }

    img {
      margin-right: 20px;
      cursor: pointer;
    }

    .cust-filter {
      width: 30px;
      height: 30px;
      margin-left: 2%;
      align-self: flex-end;

      .ant-badge-count {
        background-color: #5839cb;

      }

      img {
        margin-right: 0px;
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
    }

  }

  p {
    color: #0c0137;
    //  margin-left: 2.5rem;
    font-size: 16px;
  }
}

.product__container {
    margin-top: 40px;
    overflow: hidden;
    .product-card__container {
      // background: url("../../../../assets/images/carousel-bg.svg") no-repeat;
      background-size: cover !important;
      padding: 3rem 4rem;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      .content__left {
        width: 50%;
        display: flex;
        align-items: center;
        .profile-image__container {
          width: 90px;
          height: 90px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 45px;
          }
        }
        .profile-details__container {
          margin-left: 7%;
          p {
            color: white;
            margin: 0;
          }
          .profile__name {
            font-size: 16px;
            font-weight: lighter;
            span {
              font-weight: 600;
            }
          }
          .profile__class {
            font-size: 28px;
          }
        }
      }
      .content__right {
        display: flex;
        width: 25%;
        .points__container {
          margin-top: 2%;
          margin-left: 13%;
          p {
            font-weight: 700;
            font-style: italic;
            color: white;
            font-size: 20px;
            margin: 0;
          }
          .points {
            span {
              margin-left: 10%;
              img {
                margin-top: -3px;
                width: 22px;
                height: 22px;
              }
            }
          }
          .points__btn {
            margin-top: 8%;
            button {
              height: 40px !important;
            }
          }
        }
      }
    }
  }

  .product__carousel__wrapper {
    .ant-carousel {
      .slick-dots {
        li {
          width: 7px !important;
          button {
            border-radius: 100px !important;
            height: 7px !important;
          }
        }
      }
    }
    .carousel__wrapper {
      p,
      h1 {
        color: white;
        margin: auto;
      }
      img {
        width: 100%;
        border-radius: 20px;
      }
      .background-image__cover {
        border-radius: 20px;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width: 100%;
        padding: 1.5rem;
      }
      .heading {
        opacity: 0.7;
        font-size: 18px;
      }
  
      .title {
        font-weight: bold;
      }
  
      .tutor__wrapper {
        display: flex;
        margin-top: 0.6rem;
        .img__wrapper {
          height: 1.7rem;
          border-radius: 100px;
          img {
            height: inherit;
            width: inherit;
          }
        }
  
        p {
          margin: auto 0 auto 15px;
          font-weight: bold;
        }
      }
      .content {
        display: flex;
        margin-top: 0.5rem;
        .language-item {
          border-right: 1px solid white;
          margin-right: 10px;
        }
        p {
          padding-right: 15px;
          margin: auto 0;
        }
        span {
          opacity: 0.5;
          margin-left: 2px;
        }
        :last-child {
          padding-left: 5px;
        }
      }
    }
  }

  @media (min-width: 1200px) and (max-width: 1599px) {
    .product__container {
      .product-card__container {
        .content__right {
          width: 30% !important;
        }
      }
    }
  }
  