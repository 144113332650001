.otp-input {
	border: none;
	outline: none;
	margin: 0px 5%;
	width: 50px;
	height: 50px;
	background: transparent linear-gradient(65deg, #5839CB 0%, #5839CB 100%) 0% 0% no-repeat padding-box !important;
    border-radius: 10px;
    color: white;
    text-indent: 49%;
}
