@import "src/styles/variables";

.privacy-policy {
  background: #f5f8fa;
  height: 100vh;
  padding: 2rem;
  h1 {
    font-weight: bold;
    font-size: 40px;
  }
  .content__title {
    font-weight: 700;
    margin: 0;
  }
}
