
/* Color palette */
$label-color: #2B2B2B;
$delete-color: #FF503C;
$primary-color:#32169B;
$secondary-color: #FF668C;
$light-white: #FFFFFF40;
$primary-light-color: #0D494E10;
$primary-black: #4B4B4B;
$dark-blue: #22509D;
$light-grey: #00000015;
$dark-grey: #8898AA;
$success-color: green;
$danger-color: #FE0000;
$warning-color: #F3B21B;
$body-bg: #F5F8FA;
$table-bg: #F7FAFC;
$input-bg: #F8FBFC;
$white-color: white;
$text-light-color: #222222;
$text-color: #0C1013;
$placeholder-color: #8E8E8E;
$primary-grey: #7E899E;
$primary-background: #F0F0E7;
/* Shadows */
$primary-shadow: 0 8px 22px #0000001A;
$secondary-shadow: 0px 3px 6px #2C28281C;
$primary-button-background: transparent linear-gradient(90deg, #5839CB 0%, #32169B 100%) 0% 0% no-repeat padding-box;




/* Spacer variables */
$spacer-x : 1rem;
$spacer-y : 1rem;

/* Button colors*/
$primary-btn-color: transparent linear-gradient(96deg, #D75878 0%, #F3577D 100%) 0% 0% no-repeat padding-box;
$secondary-btn-color: #5839CB;