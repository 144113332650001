.quiz__drawer {
    display: none !important;
}
.practice-chapter__container {
    position: relative;

    .exercises__container {
        .quiz__wrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            top: -2rem !important;
            z-index: 1;
            background-color: #ffffff;
            bottom: -2rem;
            min-height: 100vh;
        }

        .chapters__wrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            top: -2rem !important;
            z-index: 1;
            bottom: -2rem;
            min-height: 100vh;
        }

        .chapters__container {
            margin-top: 13%;
            // margin-right: 1rem;
            position: relative;
            height: -webkit-fill-available;
            display: flex;
            flex-direction: column;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .exercise-badge {
        display: none !important;
    }

    .subject-card-expand__container {
        h2 {
            margin-right: 10px !important;
        }
    }

    .question__no {
        font-size: 12px;
    }
}

@media only screen and (max-width: 768px) {
    .quiz__drawer {
        display: block !important;
    }
    .navbar-container__float {
        .app-logo,
        .content-right {
            display: none !important;
        }
        .content-left {
            display: block;
            width: 100%;
        }
    }
    .application-body-modify__nav {
        padding: 0 !important;
        border-radius: 0 !important;
        width: 100%;

        .navbar-container__float {
            // background: #2E079A;
            // height: 30px;
        }
    }
    .practice-chapter__container {
        position: relative;

        .chapters__container_div {
            padding: 0 !important;
        }
        .exercises__container {
            .quiz_wrapper_col {
                display: none;
            }

            .chapters_wrapper_col {
                width: 100%;
                max-width: 100%;
                flex: 1;
                .chapters__wrapper {
                    position: unset;
                    width: 100%;
                }
            }
        }
    }

    .quizes__heading__wrapper {
        h2 {
            font-weight: 600;
        }

        p {
            color: #7e899e;
            font-size: 18px;
            margin-bottom: 0px;
        }
    }

    .ant-drawer-header-title {
        align-items: baseline !important;
    }

    .ant-drawer-header {
        padding: 7px 4px !important;
    }
}
