.option__container {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #7e899e;
  border-radius: 10px;
  padding: 25px;
  transition: padding 0.7s;
  cursor: pointer;
  .label {
    display: flex;
    span {
      margin: 0 20px auto auto !important;
      background-color: #e5e7eb;
      padding: 3px 8px;
      border-radius: 100px;
      text-align: center;
      display: inline;
      text-transform: capitalize;
      font-size: 12px;
    }
  }

  .option {
    p {
      transition: padding-left 0.7s;
      font-size: 16px;
    }
  }
}

.option-selected {
  border: 1px solid #145ce7 !important;
  background-color: #f3f9ff;
  .label {
    display: flex;
    span {
      background-color: #145ce7;
      color: white;
    }
  }
  .option {
    p {
      color: #145ce7;
    }
  }
}

.option-disabled {
  pointer-events: none;
}

.option-correct {
  border: 1px solid #108e10 !important;
  background-color: #f3f9ff;
  .label {
    display: flex;
    span {
      background-color: #108e10;
      color: #f7fff7;
    }
  }
  .option {
    p {
      color: #108e10;
    }
  }
}

.option-wrong {
  border: 1px solid #dc1313 !important;
  background-color: #fff4f4;
  .label {
    display: flex;
    span {
      background-color: #dc1313;
      color: white;
    }
  }
  .option {
    p {
      color: #dc1313;
    }
  }
}
.option__image {
  width: 15vw;
  height: 15vh;
}


@media only screen and (max-width: 768px) {
  .option__container {
    padding: 10px;
    margin-bottom: 12px;
  }
}
