@import "src/styles/variables";
.course__container {
  background: transparent linear-gradient(180deg, #5839cb 0%, #32169b 100%) 0%
    0% no-repeat padding-box;
  min-height: 100vh;
  .input-field {
    input {
      background-color: transparent;
      color: white !important;
    }
  }
  .form__wrapper {
    text-align: center;
    height: 100%;
    padding: 5% 0 5% 0;
  }
  .image__wrapper {
    position: relative;
    .panditji__logo {
      position: absolute;
      width: 224px;
      height: 70px;
      margin-left: 15%;
      top: 10%;
    }
    .school__image {
      margin-top: 5%;
      width: 80%;
      height: 75vh;
    }
  }
}

.course-form__container {
  // margin-top: 5rem;
  h1 {
    color: white;
    font-weight: bolder;
    text-align: left;
    font-size: 24px;
  }
}
.course__close {
  color: #ffffff !important;
  opacity: 0.5;
  margin-top: 2rem;
}
.skip {
  color: $secondary-color;
  text-align: center;
  margin-top: 5%;
  cursor: pointer;
}
