.subscription__wrapper {

    .ant-modal-content {
        background-color: #0C0137;
        display: flex;
        justify-content: center;
    }



    img {
        // margin: auto;
        display: flex;
        text-align: center;
        margin: auto;
    }

    .instructions {
        margin-top: 40px;

        h2 {
            font-weight: 600;
            color: white;
        }

        div {
            display: table;
            margin: auto;
        }

        .points {
            display: flex;
            color: #7E899E;
            align-items: center;
            margin: 20px 0px;
            font-size: 16px;
        }

        .icon {
            // margin: auto 8px auto auto !important;
            // margin-bottom: 5px;
            margin: 0px 10px 0px 0px;
            width: 30px;
            height: 30px;
            color: #FFCF3F;
        }

        ul {
            margin-top: 15px;

            li {
                margin: 14px 0px;
                font-size: 16px;
                color: #7E899E;
            }
        }
    }
}