@import "src/styles/variables";

.current-student-rank__container {
  width: 35%;
  margin: 6rem auto 2rem auto;
  display: flex;
  justify-content: space-between;
  background: transparent linear-gradient(260deg, #32169B 0%, #120056 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 16px #250d7e33;
  border-radius: 43px;
  padding:  0.5rem 1rem 0.5rem 0.5rem;
  .current-student-rank__left {

    align-items: center;
    display: flex;
    .current-student__image {
      width: 55px;
      height: 55px;
      border-radius: 30px;
    }
    .current-student-rank__wrapper {
      p {
        margin: 0;
      }
      margin-left: 1rem;
      .current-student-rank__label {
        color: #EEEBFA;
        font-size: 12px;
      }
      .current-student__rank {
        font-weight: 700;
        font-size: 18px;
        color: white;
      }
    }
  }
  .current-student-rank__right {
    display: flex;
    align-items: center;
    p {
      margin: 0;
      font-weight: 700;
      color: white;
      font-style: italic;
      margin-right: 1rem;
    }
    img {
      width: 50px;
      height: 50px;
      border-radius: 30px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .current-student-rank__container {
    width: 90%;
    left: 5%;
    margin: 10rem auto -2rem auto;
    position: absolute;
    z-index: 1;
  }
}
