.exam-cards__wrapper {
  display: flex;
  float: left;

  .exam-cards__item {
    background-color: white;
    width: 354px;
    height: 92px;
    box-shadow: 2px 2px 10px #0f47b20f;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    img {
        width: 60px;
        height: 60px;
        border-radius: 10px;
        object-fit:cover;
    }   

    div {
        margin: 10px 10px;
    }
    .exam__acronym {
        margin: 0;
        font-size: 18px;
        color: #0C1013;
    }
    .exam__name {
        margin: 0;
        font-size: 14px;
        color: #0C1013;
    }
  }
}
