@import "src/styles/variables";

.announcement-details {

    .announcement-details-conatiner {
        padding: 1rem 2rem 2rem;
        // height: 100%;

        .announcement-card {
            background-color: #fff;
            box-shadow: 2px 2px 10px #0f47b20f;
            border-radius: 10px;
            margin-top: 24px;
            cursor: pointer;


            &.live {
                border-left: 2px solid #5539CB;
            }

            .announcement-card-date__container {
                padding: 24px;

                .announcement-card__date {
                    font-size: 12px;
                    font-weight: 400;
                    margin-bottom: 10px;
                    color: grey;

                    img {
                        width: 20px;
                        height: 18px;
                        margin-right: 10px;
                        margin-bottom: 2px;

                    }

                    .dot {
                        display: inline-flex;
                        border-width: 3px;
                        border-style: solid;
                        width: 3px;
                        height: 3px;
                        border-radius: 50%;
                        border-color: grey;
                        margin: 0px 10px;
                    }
                }

                .announcement-card__time {
                    color: $primary-grey;
                    font-weight: 500;
                    margin-bottom: 0;

                    .anticon {
                        margin-right: 8px;
                    }
                }
            }

            .announcement-card-title__container {
                padding: 24px;

                .announcement-card__title {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 12px;
                }

                .announcement-card-more__container {
                    display: flex;
                    align-items: center;
                    column-gap: 20px;

                    p {
                        margin-bottom: 0;
                    }

                    .announcement-card__tutors {
                        display: flex;
                        min-width: 10%;

                        img {
                            width: 20px;
                            height: 20px;
                            border-radius: 10px;
                            margin-left: -5px;
                            border: 1px solid #FFFFFF;

                            &:first-child {
                                margin-left: 0;
                            }
                        }

                        span {
                            color: $primary-grey;
                            font-weight: 500;
                            margin-right: 5px;
                        }
                    }

                    .announcement-card__subject {
                        min-width: 15%;

                        img {
                            width: 10px;
                            height: 13px;
                            margin-right: 5px;
                            margin-bottom: 2px;

                        }

                        font-weight: 500;
                        color: $primary-grey;
                    }

                    .announcement-card__class {
                        img {
                            width: 12px;
                            height: 12px;
                            margin-right: 10px;
                            margin-bottom: 2px;
                        }

                        font-weight: 500;
                        color: $primary-grey;
                    }
                }
            }

            .announcement_comment__container {
                border-radius: 0px 0px 3px 3px;
                padding: 15px 24px;
                border-top-style: solid;
                border-color: rgb(158, 169, 178);
                background-color: aliceblue;
                border-width: 1px;
                height: 50vh;
                overflow-y: auto;

                .announcement-comment__input {
                    padding: 2px 20px;
                    padding-bottom: 10px;

                    input {
                        border-radius: 20px;
                    }
                }

                .announcement-comment__card {
                    padding: 5px 24px;
                    display: flex;

                    .announcement-comment__text {

                        padding: 5px 10px;
                        border-radius: 10px;
                        background-color: #fff;
                        width: 100%;
                        display: grid;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 1.5;

                        .announcement-comment__postby {
                            font-size: 12px;
                            font-weight: 600;
                            color: gray;

                        }
                    }
                }

                .more_label {
                    color: cornflowerblue;
                    cursor: pointer;
                    text-align: end;
                    font-size: small;
                }

            }

        }
    }
}