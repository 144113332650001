@import "src/styles/variables";

.related-content__container {
  margin: 0px 20px 10px;
  height: 1000px;
  overflow: scroll;
  // -ms-overflow-style: none;
  // scrollbar-width: none;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  .title {
    color: $text-color;
    font-weight: bolder;
    font-size: 18px;
  }
  .related-video__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 25px;
  }
}

@media only screen and (max-width: 768px) {
  .related-content__container {
    height: auto !important;
    // position: absolute;
    width: 100%;
    margin: 0rem 0px 0px 5px !important;
  }
}
