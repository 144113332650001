.questions-list__container {
    padding: 20px 25px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100vh;
    opacity: 1;
    transition: opacity 0.4s;
    .ant-tabs-tab-active {
        #rc-tabs-2-tab-1 {
            img {
                content: url('../../../../../assets/icon/switch-question-blue.svg');
            }
        }
        #rc-tabs-2-tab-2 {
            img {
                content: url('../../../../../assets/icon/analytics-blue.svg');
            }
        }
    }
    .tab__name {
        margin: 0;
        font-size: 16px;

        img {
            width: 14px;
            height: 14px;
            margin-right: 10px;
            margin-top: -3px;
        }
    }
    .retake-test__wrapper {
        margin: 1.5rem auto;
    }

    .title {
        font-size: 15px;
        margin-bottom: 30px;
        color: #000;
    }

    .questions-pallet {
        margin-top: 2rem;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 15px 20px;
    }
}

.question-list__shrink {
    opacity: 0;
}
.exercise__title {
    font-weight: bolder;
    font-size: 20px;
    margin-bottom: 10px;
    color: #000;
    cursor: pointer;
    span {
        margin-right: 10px;
    }
}
.question-stats__wrapper {
    border-left: 4px solid;
    padding-left: 8px;
    .nos {
        margin: auto auto 5px;
    }
}
// .attempts__dropdown {
//   margin-left: 35px;
// }
// .divider__line {
//   margin: 50px auto !important;
// }
// .measures__card {
//   border-radius: 10px;
//   width: 100%;
//   height: 105px;
//   margin-bottom: 15px;
//   text-align: center;
//   position: relative;
//   p:first-child {
//     margin: 0;
//   }
//   div {
//     position: absolute;
//     bottom: 10px;
//     width: 100%;
//   }
//   img {
//     position: absolute;
//     top: -30%;
//     right: 40%;
//     left: 40%;
//   }
//   .measure__value {
//     font-size: 24px;
//     margin: 0;
//   }
//   .measure__title {
//     font-size: 14px;
//     margin: 0;
//   }
// }
// .accuracy {
//   background: rgba(208, 40, 44, 0.1);
//   color: rgba(208, 40, 44, 1);
// }
// .score {
//   background: rgba(29, 123, 210, 0.1);
//   color: rgba(29, 123, 210, 1);
// }
// .speed {
//   background: rgba(255, 119, 33, 0.1);
//   color: rgba(255, 119, 33, 1);
// }
// .duration {
//   background: rgba(85, 139, 88, 0.1);
//   color: rgba(85, 139, 88, 1);
// }
// .measure-cards__container {
//   margin-top: 15%;
//   margin-bottom: 10%;
// }
// .rank__container {
//   background: url("../../../../../assets/icon/cloud.svg");
//   background-repeat: no-repeat;
//   height: 150px;
//   width: 60%;
//   display: block;
//   margin: auto;
//   position: relative;
//   margin-top: 10%;
//   .rank-picture__container {
//     position: absolute;
//     top: 25%;
//     left: 40%;
//     img {
//       width: 88px;
//       height: 88px;
//     }
//     .rank {
//       position: absolute;
//       top: 80%;
//       left: 15%;
//       background: transparent
//         linear-gradient(
//           90deg,
//           rgba(247, 151, 30, 1) 0%,
//           rgba(255, 210, 0, 1) 100%
//         )
//         0% 0% no-repeat padding-box;

//       border-radius: 16px;
//       padding: 5px 0;
//       color: #fff;
//       font-weight: bold;
//       font-size: 16px;
//       width: 75%;
//       display: block;
//       margin: auto;
//       text-align: center;
//     }
//   }
// }
// .rank__text {
//   position: absolute;
//   top: 100%;
//   left: 48%;
//   color: #7e899e;
// }

@media only screen and (max-width: 768px) {
    .questions-list__container {
        padding: 0px 5px;
    }
}
