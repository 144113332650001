@import "src/styles/variables";

.live-class-meeting {
    padding: 24px 16px 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $primary-background;
}
