.question__wrapper {
	text-align: center;
	background-color: rgba(145, 155, 176, 0.2);
	border-radius: 10px;
	height: 45px;
	width: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	span {
		color: rgba(145, 155, 176, 1);
		width: 30px;
		font-weight: bold;
	}
}

.question-visited {
	background-color: #d0defa;
	span {
		color: #145ce7;
	}
}

.question-correct {
	background-color: #cfe8cf;
	span {
		color: #108e10;
	}
}
.question-marked {
	background-color: #FFEACF;
	span {
		color: #FF9810;
	}
}
.question-wrong {
	background-color: #f8cfcf;
	span {
		color: #dc1313;
	}
}
