@import "src/styles/variables";

.search__input {
  background-color: #245fd0 !important;
  padding: 10px 10px !important;
  border-radius: 5px !important;
  input {
    color: white !important;
  }

  opacity: 0.8;
  margin-top: 1rem;
}
.school-board__card {
  // background: transparent linear-gradient(65deg, #3475f6 0%, #1b5ee0 100%) 0% 0%
  //   no-repeat padding-box !important;
  border: none !important;
  border-radius: 10px !important;
  padding: 10px !important;
  height: 85px !important;
  color: white !important;
  margin: 5px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.card__border {
  border: 2px solid white !important;

}
.new-boards__wrapper {
  height: 45vh;
  overflow-y: scroll;
  margin-top: 2rem;
  padding: 5px 0px !important;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    &:hover {
        background: rgba(255, 255, 255, 0.4);
      }
  }


}
.btn__wrapper--margin {
  margin-top: 2rem;
}
.card__icon {
  width: 60px;
  height: 60px;
  border-radius: 5px;
}
.board__checkbox {
  margin-right: 25px !important;

  span {
    span {
      background-color: #3475f6 !important;
      border: 2px solid #528eff !important;
    }
  }
}
