.list-subjects__container {
    margin-top: 40px;
    // .list-subject__wrapper {
    //     display: grid;
    //     grid-template-columns: repeat(7, 1fr);
    //     grid-gap: 15px;
    // }
}

@media only screen and (max-width: 768px) {
    .list-subjects__container {
        margin-top: 0px;
        margin-bottom: 10rem;

        .list-subject__wrapper {
            overflow-x: scroll;
            margin: 0 !important;
            margin-right: 0 !important;
            margin-bottom: 2rem;
            justify-content: space-between;
            overflow-x: hidden;
            overflow-y: scroll;

            div {
                margin: 0 !important;
                margin-right: 0 !important;
            }
        }
    }
}
