@import "src/styles/variables";

.already-have-acc {
  color: white;
  font-size: 16px;
  margin-top: 2rem;
  span {
    color: $secondary-color;
    font-weight: bold;
    cursor: pointer;
  }
}
.btn__wrapper {
  p {
    text-align: center;
  }
}
.terms-and-conditions__checkbox {
  padding-top: 2rem !important;

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $secondary-color !important;
    border-color: $secondary-color !important;
    &:hover {
      border-color: $secondary-color !important;
    }
  }
}
.terms-and-conditions {
  color: white;
  text-align: center;
  span {
    color: $secondary-color;
    cursor: pointer;
  }
}
.register-form {
  margin-top: 5rem;
  .input-field {
    input {
      background-color: transparent;
      text-indent: 15px;
      color: white !important;
    }
    .ant-input-suffix {
      margin-right: 10px;
    }
  }

  h1 {
    color: white;
    font-weight: bolder;
    text-align: left;
    font-size: 24px;
  }
  .btn__wrapper {
    margin-top: 4rem;
  }
}
.ant-picker-input {
  .ant-input-suffix {
    margin-right: 20px !important;
  }
}
