@import "src/styles/variables";

.delete-live-class-alert {
    text-align: center;
    .alert-icon {
        width: 60px;
        height: 60px;
    }
    .title {
        font-weight: 600;
        font-size: 18px;
        margin-top: 30px;
        margin-bottom: 0 !important;
    }
    .subtitle {
        margin-top: 10px;
        color: $primary-grey;
        font-size: 14px;
        margin-bottom: 0 !important;

    }
    .controllers {
        margin-top: 38px;
        display: flex;
        column-gap: 21px;
    }
}
.delete-live-class-alert__modal {
    width: 354px !important;
    .ant-modal-content {
        min-height: unset !important;
    }
  
}