@import "variables";
@import "helpers";
@import "antOverrides";

/* General component styles */

@font-face {
  font-family: "SF Pro";
  font-style: normal;
  font-weight: 300;
  src: local("SF Pro"), local("SFPro"),
    url("../assets/fonts/FontsFree-Net-SFProDisplay-Light.ttf") format("truetype");
}

@font-face {
  font-family: "SF Pro";
  font-style: italic;
  font-weight: 300;
  src: local("SF Pro"), local("SFPro"),
    url("../assets/fonts/FontsFree-Net-SFProDisplay-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "SF Pro";
  font-style: normal;
  font-weight: 400;
  src: local("SF Pro"), local("SFPro"),
    url("../assets/fonts/FontsFree-Net-SFProDisplay-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "SF Pro";
  font-style: normal;
  font-weight: 500;
  src: local("SF Pro"), local("SFPro"),
    url("../assets/fonts/FontsFree-Net-SFProDisplay-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "SF Pro";
  font-style: normal;
  font-weight: 600;
  src: local("SF Pro"), local("SFPro"),
    url("../assets/fonts/FontsFree-Net-SFProDisplay-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: "SF Pro";
  font-style: normal;
  font-weight: 700;
  src: local("SF Pro"), local("SFPro"),
    url("../assets/fonts/FontsFree-Net-SFProDisplay-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "SF Pro";
  font-style: italic;
  font-weight: 700;
  src: local("SF Pro"), local("SFPro"),
    url("../assets/fonts/FontsFree-Net-SFProDisplay-BoldItalic.ttf") format("truetype");
}

body {
  background-color: #ffffff;
  color: $primary-black;
}

body,
h1,
h2,
h3,
h4,
h5,
button,
p,
input {
  font-family: "SF Pro" !important;
  color: #0c0137;
}

.display-none {
  visibility: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.application-body {
  padding: 2rem;
  background-color: #f0f0e7;
  border-radius: 30px 0px 0px 30px;
  overflow-y: auto;
}

.application-body-two-bgs {
  background: linear-gradient(to bottom,
      #200e52 0%,
      #200e52 400px,
      #f0f0e7 400px,
      #f0f0e7 100%);
  padding: 2rem;
  border-radius: 30px 0px 0px 30px;
  overflow-y: auto;
  width: 100vw;
}

.application-body-two-bgs.ssvmkidscasa {
  background: linear-gradient(to bottom, #136367 0%, #136367 400px, #f0f0e7 400px, #f0f0e7 100%);
}

.application-body-modify__nav {
  padding: 2rem 0 2rem 2rem;
  background-color: #f0f0e7;
  border-radius: 30px 0px 0px 30px;
  overflow-y: auto;

}

.application-body__full-view {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .navbar__container,
  .navbar-container__float {
    display: none;
  }
}

.application-body-for-study__material {
  background-color: #f0f0e7;
  border-radius: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}


/* Styled scrollbar */
::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(73, 73, 73, 0.2);
  border-radius: 10px;

  &:hover {
    background: rgba(73, 73, 73, 0.3);
  }
}


.animate__bell {
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% {
    transform: rotate(0);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  21% {
    transform: rotate(18deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

@-moz-keyframes ring {
  0% {
    transform: rotate(0);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  21% {
    transform: rotate(18deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  10% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  21% {
    transform: rotate(18deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

@media only screen and (max-width: 768px) {
  .application-body {
    margin: 1px;
    padding: 0;
    background-color: unset;
    height: 99vh;
  }

  ::-webkit-scrollbar {
    width: 0;
  }

  .application-body-two-bgs {
    width: 100vw;
    border-radius: 0;
  }
}
