@import "src/styles/variables";


.ui-button {
  width: 100% !important;
  background: transparent linear-gradient(96deg, #d75878 0%, #f3577d 100%) 0% 0% no-repeat padding-box !important;
  border: none !important;
  color: white !important;
  height: 50px !important;
  font-weight: 600 !important;

  border-radius: 10px;
  border-radius: 8px !important;
  span {
    margin: auto 0;
  }
}
.ui-button__flex {
  span {
    display: flex !important;
    justify-content: space-between !important;
  }
}

.ui-button__info {
  background: transparent linear-gradient(260deg, #5839cb 0%, #32169b 100%) 0% 0% no-repeat padding-box !important;
  text-align: center !important;
}
.ui-button__success {
  background: transparent linear-gradient(260deg, #39cb87 0%, #39cb87 100%) 0% 0% no-repeat padding-box !important;
  text-align: center !important;
}

.ui-button__center {
  margin: auto;
  span {
    margin: auto !important;
  }
}

.ui-button__secondary {
  background: transparent linear-gradient(90deg, #f7971e 0%, #ffd200 100%) 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 10px !important;
}
.ui-button__ghost {
  background: white !important;
  border: 0.5px solid #7e899e !important;
  border-radius: 10px !important;
  color: #7e899e !important;
}

.ui-button__danger {
  background: #DC1313 !important;
  color: #fff !important;
  border-radius: 10px !important;
  border: none !important;
}
.ui-button__outline {
  background: transparent !important;
  border: 1px solid $secondary-btn-color !important;
  span {
    color: $primary-color !important;
  }
}