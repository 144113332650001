@import "src/styles/variables";

.ant-upload-list {
  .ant-btn {
    height: 20px !important;
    border: none !important;
  }
}

.ant-btn[disabled]{
  box-shadow:none !important;
}

.upload__errors {
  margin-top: 2rem;
  color: red;

  p {
    margin-bottom: 0.2rem;
  }

  .error__text {
    span {
      margin-right: 5px;
      margin-top: 5px;
    }
  }
}

.bulk-upload__title {
  font-weight: 700;
}

.upload__wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  .ant-tooltip {
    display: none;
  }

  .ant-upload-span {
    button {
      span {
        color: red !important;
      }
    }

    span {
      color: $primary-black !important;
    }
  }

  .upload__btn {
    box-shadow: 0px 3px 6px #00000029;
    width: 100% !important;
    height: 50px !important;
    font-weight: 600 !important;
    border-radius: 10px;
    border-radius: 8px !important;

    span {
      margin: auto 0;
    }

    background: transparent linear-gradient(260deg, #5839cb 0%, #32169b 100%) 0% 0% no-repeat padding-box !important;
    color: #fff;
    position: sticky;
  
  }

  .ant-btn:hover, .ant-btn:focus {
    color: #fff;
    border-color:transparent;
}

  .upload__item {
    display: flex;
    width: 30%;
    height: 100px;
  }

  .file__type {
    margin-top: 8px;
  }
}