@import "src/styles/variables";

.pagination__component {
  padding: 20px 0;
  border-radius: 10px;
  text-align: right;

  .ant-pagination .mini{
    box-shadow: 0 2px 10px #6666660F;
  }

  ul li {
    background-color: #FFFFFF !important;
    font-size: 12px !important;
  }

  ul li a{
    color: $placeholder-color !important;
  }

  .ant-pagination-prev{
    border-radius: 2px 0 0 2px;
    padding-left: 5px !important;
  }
  .ant-pagination-next {
    border-radius: 0 2px 2px 0;
    padding-right: 5px !important;

  }

  .ant-pagination-item,.ant-pagination-prev,.ant-pagination-next{
    height: 30px !important;
    line-height: 28px !important;
    &:hover,&:focus{
      color: $placeholder-color !important;
    }
  }

  .ant-pagination-item a{
    padding: 0 10px;
  }

  .ant-pagination-item-active{
    background-color: $primary-color !important;
    border: unset;
  }

  .ant-pagination-item-active a{
    color: #FFFFFF !important;
  }

}
