@import "src/styles/variables";

.leaderboard-info__container {
  .leaderboard-info__header {
    display: flex;

    img {
      width: 30px;
      height: 30px;
    }
    .leaderboard-info-header__content {
      margin-left: 1rem;
      p {
        margin: 0;
      }
      .leaderboard-info-header-content__title {
        font-weight: 700;
        margin-bottom: 0.5rem;
      }
      .leaderboard-info-header-content__desc {
        color: #7e899e;
        font-size: 12px;
      }
    }
  }
  .leaderboard-info__body {
      margin-top: 2rem;
    .leaderboard-info-point__container {
      display: flex;
      margin-bottom: 2rem;
      p {
          margin: 0;
      }
      .leaderboard-info__point {
        font-weight: 700;
        font-style: italic;
        color: #feaa2e;
        width: 15%;
      }
      .leaderboard-info-point__desc {
        font-weight: 500;
        width: 70%;
      }
    }
  }
}
.leaderboard-info {
    width: 420px !important;
  }