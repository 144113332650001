
  .add-exam-form__container {
    margin-top: 5rem;
    .exam-board__card {
      background-color: #11ac84 !important;
      border: none !important;
      border-radius: 10px !important;
      padding: 30px !important;
      height: 100px !important;
      margin: 10px 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .exam-card__border {
      border: 2px solid white !important;
    }
    .exams__details {
      p {
        margin: 0;
  
      }
    }
    .card__icon {
      width: 60px;
      height: 60px;
      border-radius: 30px;
      margin-left: 2rem;
    }
    .exam-details__wrapper {
      margin-left: 1.5rem;
      p {
        font-size: 18px;
        color: white;
        font-weight: 600;
        text-align: start;
        margin: 0;
      }
      .acronym {
        font-size: 12px;
        color: white;
      }
   
    }
    .search__input {
      background-color: #5839cb !important;
      padding: 15px 10px !important;
      border-radius: 10px !important;
      text-indent: 10px;
      input {
        color: white !important;
      }
      color: white;
      opacity: 0.5;
      margin-top: 1rem;
    }
    h1 {
      color: white;
      font-weight: bolder;
      text-align: left;
      font-size: 24px;
    }
    .exam-btn__wrapper {
      margin-top: 5rem;
    }
    
    .exams__wrapper {
      height: 38vh;
      overflow-y: scroll;
      margin-top: 2rem;
      padding: 5px 0px !important;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
  
      &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 10px;
        &:hover {
          background: rgba(255, 255, 255, 0.4);
        }
      }
    }
  }
  