.analytics__container {
  .analytics__dropdown {
      width: 9%;
      margin-left: 2%;
    .ant-select-selector {
      height: 3rem !important;
      border-radius: 6px !important;
      border: none !important;
      color: white !important;
      background-color: transparent !important;
      box-shadow: none !important;
    }
    .ant-select-arrow {
        color: white !important;
    }
  }

}

@media only screen and (max-width: 768px) {
  .analytics__container {
    height: 100%;
    .analytics__card {
      display: none;
    }
  }

}
