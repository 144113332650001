.cart__container {
    margin-top: 40px;
    overflow: hidden;
    .cart-card__container {
      // background: url("../../../../assets/images/carousel-bg.svg") no-repeat;
      background-size: cover !important;
      padding: 3rem 4rem;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      .content__left {
        width: 50%;
        display: flex;
        align-items: center;
        .profile-image__container {
          width: 90px;
          height: 90px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 45px;
          }
        }
        .profile-details__container {
          margin-left: 7%;
          p {
            color: white;
            margin: 0;
          }
          .profile__name {
            font-size: 16px;
            font-weight: lighter;
            span {
              font-weight: 600;
            }
          }
          .profile__class {
            font-size: 28px;
          }
        }
      }
      .content__right {
        display: flex;
        width: 25%;
        .points__container {
          margin-top: 2%;
          margin-left: 13%;
          p {
            font-weight: 700;
            font-style: italic;
            color: white;
            font-size: 20px;
            margin: 0;
          }
          .points {
            span {
              margin-left: 10%;
              img {
                margin-top: -3px;
                width: 22px;
                height: 22px;
              }
            }
          }
          .points__btn {
            margin-top: 8%;
            button {
              height: 40px !important;
            }
          }
        }
      }
    }
  }



  @media (min-width: 1200px) and (max-width: 1599px) {
    .product__container {
      .product-card__container {
        .content__right {
          width: 30% !important;
        }
      }
    }
  }
  