@import "src/styles/variables";

.ui-modal {
  .ant-modal-content {
    min-height: 50vh;
  }
  .ui-modal__title {
    font-size: 18px;
    color: #0c1013;
    font-weight: bold;
    margin: 0;
  }
  .ui-modal__subtitle {
    font-size: 14px;
    color: #0c1013;
    font-weight: lighter;
  }
}
