@import "src/styles/variables";

.filter {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  visibility: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: 0.5s visibility;
  overflow: hidden;
  position: fixed;
  z-index: 999;

  .filter-form__input {
    margin: 2rem 1rem;

    .dropdown-field {
      .ant-select-selector {
        background-color: #f8f8fa !important;
        border: none !important;
        box-shadow: none !important;
      }
    }

    .filter-form__label {
      margin-bottom: 0.5rem;
    }
  }

  .filter__container {
    float: right;
    position: absolute;
    right: 0;
    background-color: white;
    height: 100%;
    width: 0;
    transition: 0.5s ease-in width;

    .filter__title {
      background: $primary-button-background;
      padding: 1rem 3rem;
      font-size: 24px;
      color: white;
      font-weight: 600;
    }

    .filter__close {
      position: absolute;
      padding: 10px 15px;
      background-color: white;
      border-radius: 25px;
      left: -12%;
      top: 2%;
    }
  }

  .filter-container__expand {
    width: 30%;
  }

  .filter-container___collapse {
    width: 0;
  }

  .filter__children {
    overflow: scroll;
    height: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 5%;
    padding-bottom: 30%;
  }

  .filter__children::-webkit-scrollbar {
    display: none;
  }

  .filter__footer {
    border-top: 0.5px solid rgba(142, 142, 142, 0.2);
    padding: 5%;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: white;
  }
}

.filter__expand {
  visibility: visible;
}

.filter___collapse {
  visibility: hidden;
}