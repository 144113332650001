.board-form__container {
  margin-top: 5rem;

  h1 {
    color: white;
    font-weight: bolder;
    text-align: left;
    font-size: 24px;
    span {
      margin-right: 2%;
    }
  }
  .btn__wrapper {
    margin-top: 1rem;
  }
  .search__input {
    background-color: #5839CB !important;
    // padding: 15px 10px !important;
    border-radius:10px !important;
    text-indent: 10px;
    input {
      color: white !important;
    }
    color: white;
    opacity: 0.5;
    margin-top: 1rem;
  }
  .school-board__card {
    background-color: #11ac84 !important;
    border: none !important;
    border-radius: 10px !important;
    padding: 10px 30px !important;
    height: 90px !important;
    margin: 10px 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .boards__wrapper {
    max-height: 30vh;
    overflow-y: scroll;
    margin-top: 1rem;
    padding: 5px 0px !important;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 10px;
      &:hover {
        background: rgba(255, 255, 255, 0.4);
      }
    }
  }

  .card__icon {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    margin-left: 2rem;
  }
  .board-details__wrapper {
    margin-left: 1.5rem;
    p {
      font-size: 16px;
      color: white;
      font-weight: 600;
      text-align: start;
    }
    .acronym {
      font-size: 12px;
      color: white;
    }
  }
}
