@import 'src/styles/variables';

.auth__container {
  background: transparent linear-gradient(180deg, #5839cb 0%, #32169b 100%) 0%
    0% no-repeat padding-box;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .input-field {
    input {
      background-color: transparent;
      color: white !important;
    }
  }
  .form__wrapper {
    text-align: center;
    padding-top: 2rem;
  }

  .form__wrapper.ssvm {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: 100%;
    padding: 2rem;
  }
  
  .not-found__info {
    color: $secondary-color;
    padding: 2rem;
  }
  .auth-container__wrapper {
    height: 100%;
  }
  .image__wrapper {
    height: 100%;
    position: relative;
    img {
      height: 100%;
    }
    .mindjjo__logo {
      position: absolute;
      top: 5%;
      left: 10%;
      width: 220px;
      height: 80px;
    }
  }
  .mindjjo__logo {
    margin-top: 1rem;
    width: 200px;
    height: 60px;
  }
  .step__counter {
    margin-top: 3rem;
    display: flex;
    text-align: center;
    justify-content: space-between;
    background-color: #1b5ee0;
    color: rgba($color: white, $alpha: 0.5);
    border-radius: 25px;
    :first-child {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
    }
    :last-child {
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }
    .current__step {
      background-color: rgba($color: white, $alpha: 0.5);
    }
    .step__item {
      padding: 10px 10px;
      width: 100%;
      text-align: center;
    }
    .visited__step {
      background: #f7971e !important;
    }
  }
}

.auth__container.ssvmkidscasa {
  background: #136367;
}

@media only screen and (max-width: 768px) {
  .auth__container {
    .form__wrapper {
      text-align: center;
      padding-top: 0px;
      margin: 0px;
    }
    .image__wrapper {
      position: relative;
      width: 100vw;
      height: 100%;
      overflow: hidden;
      img {
        position: absolute;
        height: 286px;
        width: 60vh;
        object-fit: cover;
        bottom: 0;
      }
    }
  }
}
