@import "src/styles/variables";

.password-update-link {
    margin-top: 8rem;
    h2 {
        color: white;
        font-weight: bolder;
        text-align: center;
        margin-bottom: 1rem;
    }
    .password-update-link__info {
        color: white;
    }
    .password-update-link__controllers {
        margin-top: 8rem;
    }
}
