.practice__container {
    margin-top: 2.5rem;
    .practice-card__container {
        // background: url("../../../../assets/images/carousel-bg.svg") no-repeat;
        background-size: cover !important;
        padding: 3rem 4rem;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        position: relative;
        .content__left {
            width: 30%;
            display: flex;
            align-items: center;
            .profile-image__container {
                width: 90px;
                height: 90px;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 45px;
                }
            }
            .profile-details__container {
                margin-left: 7%;
                p {
                    color: white;
                    margin: 0;
                }
                .profile__name {
                    font-size: 28px;
                    font-weight: 600;
                }
                .profile__class {
                    font-size: 16px;
                    font-weight: 300;
                }
            }
        }
        .content__right {
            display: flex;
            align-items: center;
            width: 60%;
            .profile__measure {
                display: flex;
                width: 33%;
                .profile-measure__icon {
                    width: 80px;
                    height: 90px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .measure__wrapper {
                    margin-left: 5%;
                    p {
                        margin: 0;
                    }
                    .measure__value {
                        font-size: 28px;
                        font-weight: 600;
                        color: white;
                    }
                    .measure__desc {
                        font-weight: 400;
                        font-size: 14px;
                        color: white;
                    }
                }
            }
        }
        .info__icon {
            position: absolute;
            bottom: 15%;
            right: 2%;
            width: 20px;
            height: 20px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
@media (min-width: 1200px) and (max-width: 1599px) {
    .practice-card__container {
        .content__right {
            width: 70% !important;
        }
        .profile__name {
            font-size: 20px !important;
        }
        .profile-measure__icon {
            width: 70px !important;
            height: 70px !important;
            img {
                width: 100% !important;
                height: 100% !important;
            }
        }
        .measure__value {
            font-size: 17px !important;
        }
    }
}

@media only screen and (max-width: 768px) {
    .application-body {
        width: 100%;
    }
    .practice-module__container {
        margin-bottom: 3rem;
        width: 100%;
        .practice__container {
            margin-top: 0rem;

            .practice-card__container {
                padding: 3rem 0rem;
                .content__left {
                    display: none !important;
                }
                .content__right {
                    margin-top: 3rem;
                    width: 100%;

                    .profile__measure {
                        margin: 10px;
                        border-radius: 10px;

                        display: block;

                        background: #b7c0ee29 !important;
                        .profile-measure__icon {
                            height: 50px;
                            width: 50px;
                            margin: auto;
                            position: relative;
                            top: -20px;
                        }
                        .measure__wrapper {
                            margin: 0px;
                            .measure__value {
                                font-size: 14px;
                            }
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}
