.exam-pannel-controller__container {
    display: flex;
    justify-content: space-between;
    .exam-panel-controller__left {
        display: flex;
        justify-content: space-around;
        width: 100%;
    }
    .exam-panel-controller__right {
        width: 15%;
        button {
            span {
                text-align: center !important;
            }
        }
    }
    .exam-button-rounded__container {
        border-radius: 25px;
        width: 50px;
        height: 50px;
        border: 0.3px solid #7e899e;
        color: #7e899e;
        text-align: center;
        transition: color 0.5s;
        padding: 12px;
        &:hover {
            transition: 0.5s border-color;
            border: 1px solid #145ce7;
        }
        cursor: pointer;
    }
    .exam-button-ghost__container {
        border-radius: 10px;
        width: 130px;
        height: 50px;
        border: 0.3px solid #7e899e;
        color: #7e899e;
        text-align: center;
        transition: color 0.5s;
        padding: 12px;
        &:hover {
            transition: 0.5s border-color;
            border: 1px solid #145ce7;
        }
        cursor: pointer;
    }
    .exam-button-submit__container {
        width: 130px;
        height: 50px;
        background: transparent
            linear-gradient(250deg, #145ce7 0%, #0f47b2 100%) 0% 0% no-repeat
            padding-box;
        border-radius: 10px;
        text-align: center;
        transition: color 0.5s;
        padding: 12px;
        color: white;
        cursor: pointer;
    }

    .exam-button__container {
        border: 0.30000001192092896px solid #7e899e;
        border-radius: 10px;
        color: #7e899e;
        padding: 10px;
        margin: auto 0;
        text-align: center;
        transition: color 0.5s;
        width: 150px;
        .icon {
            transition: padding 0.5s, opacity 0.5s;
            opacity: 0;
        }

        .icon-left {
            transition: padding 0.5s, opacity 0.5s;
            opacity: 0;
        }

        &:hover {
            border-color: #145ce7;
            color: #145ce7;
            cursor: pointer;

            .icon {
                opacity: 1;
                padding-left: 15px;
            }

            .icon-left {
                opacity: 1;
                padding-right: 15px;
            }
        }
    }

    .exam-button__disabled {
        color: white;
        border: none;
        pointer-events: none;
    }
}

@media only screen and (max-width: 768px) {
    .exam-pannel-controller__container {
        justify-content: space-around !important;
    }
}
