.ant-progress {
	.ant-progress-outer {
		.ant-progress-inner {
			.ant-progress-bg {
				background: transparent
					linear-gradient(90deg, #f7971e 0%, #ffd200 100%) 0% 0%
					no-repeat padding-box;
				height: 5px !important;
			}
		}
	}
	// .ant-progress-text {
	// 	display: none !important;
	// }
}
