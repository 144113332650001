@import "src/styles/variables";

.ant-btn{
  height: 40px !important;
  border-radius: 10px !important;
  border: unset !important;
  width: 100%;
  img{
    width: 10%;
    margin-left: 10px;
  }
}

.ant-btn-primary{
  background: $primary-button-background !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.ant-btn-text{
  border: 1px solid #8E8E8E90 !important;
  border-radius: 10px;
  color: $placeholder-color !important;
}
