@import 'src/styles/variables';

.main__container {
    background: transparent linear-gradient(180deg, #5839cb 0%, #32169b 100%) 0%
        0% no-repeat padding-box;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    .input-field {
        input {
            background-color: transparent;
            color: white !important;
        }
    }
    .form__wrapper {
        text-align: center;
        padding-top: 2rem;
    }
    .not-found__info {
        color: $secondary-color;
        padding: 2rem;
    }
    .main-container__wrapper {
        height: 100%;
    }
    .image__wrapper {
        height: 100%;
        position: relative;
        img {
            height: 100%;
        }
        .mindjjo__logo {
            position: absolute;
            top: 5%;
            left: 10%;
            width: 220px;
            height: 80px;
        }
    }
    .mindjjo__logo {
        margin-top: 1rem;
        width: 200px;
        height: 60px;
    }
}

.main__container.ssvmkidscasa {
    background: transparent linear-gradient(180deg, #136367 0%, #136367 100%) 0%
        0% no-repeat padding-box;
}

@media only screen and (max-width: 768px) {
    .main__container {
        .form__wrapper {
            padding-top: 0px !important;
            margin-top: 0px !important;
        }
        .image__wrapper {
            img {
                height: 42vh;
                object-fit: cover;
            }
        }
    }
}
