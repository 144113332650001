@import "src/styles/variables";

.product-card {
    background-color: #fff;
    padding: 0 24px;
    box-shadow: 2px 2px 10px #0f47b20f;
    border-radius: 10px;
    // margin-top: 24px;
    // cursor: pointer;

    .class-img {
        img {
            object-fit: contain;
            width: -webkit-fill-available;
        }

    }

    .white-wrap {
        padding: 0.5rem 0rem 0rem;
        background: #ffffff;
        .more_details {
            color: cornflowerblue;
            cursor: pointer;
            text-align: end;
            font-size: small;
        }
    }

    .cart-heading {
        // margin-bottom: 2rem;
    }

    .heading01 {
        font-size: 1.2rem;
        // line-height: 4.6rem;
        font-family: "Quicksand", sans-serif;
        color: #5839CB;
        font-weight: 600;
    }

    .main-btn-3 {
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        border: 0;
        padding: 0 4.5rem;
        font-size: 16px;
        line-height: 40px;
        cursor: pointer;
        background-color: #D75878;
        color: #fff;
        border-radius: 5rem;
    }

    .class-text {
        p {
            margin-top: 0;
            margin-bottom: 0.2rem;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-size: 18px;
            line-height: 25px;
            color: #000;
        }
    }

    &.btn {
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition: 0.2s;

        &::before {
            content: '';
            height: 3px;
            width: 0;
            left: 0;
            bottom: 0;
            position: absolute;
            background: #5539CB;
            transition: 0.4s;
        }

        &:hover {
            &::before {
                width: 100%;
            }
        }

        &:active {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }


    &.live {
        border-left: 2px solid #5539CB;
    }

    .product-card-date__container {
        // border-right: 1px solid #eeeeee60;
        padding: 24px 0;

        .product-card__date {
            display: flex;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 10px;

            .__text {
                display: inline-grid;
            }

            .__sub_title {
                font-size: 12px;
                font-weight: bold;
                padding: 0px;
                line-height: 1.2;
                color: #56595f;
            }

            img {
                width: 25px;
                height: 25px;
                margin-right: 10px;
                margin-bottom: 2px;

            }
        }

        .product-card__time {
            color: $primary-grey;
            font-weight: 500;
            margin-bottom: 0;

            .anticon {
                margin-right: 8px;
            }
        }
    }

    .product-card-title__container {
        padding: 24px;

        .product-card__title {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 12px;
        }

        .product-card-more__container {
            display: flex;
            align-items: center;
            column-gap: 20px;

            p {
                margin-bottom: 0;
            }

            .product-card__tutors {
                display: flex;
                min-width: 10%;

                img {
                    width: 20px;
                    height: 20px;
                    border-radius: 10px;
                    margin-left: -5px;
                    border: 1px solid #FFFFFF;

                    &:first-child {
                        margin-left: 0;
                    }
                }

                span {
                    color: $primary-grey;
                    font-weight: 500;
                    margin-right: 5px;
                }
            }

            .product-card__subject {
                min-width: 15%;

                img {
                    width: 10px;
                    height: 13px;
                    margin-right: 5px;
                    margin-bottom: 2px;

                }

                font-weight: 500;
                color: $primary-grey;
            }

            .product-card__class {
                img {
                    width: 12px;
                    height: 12px;
                    margin-right: 10px;
                    margin-bottom: 2px;
                }

                font-weight: 500;
                color: $primary-grey;
            }
        }
    }

    .product-card__actions {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

}