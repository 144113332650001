.ant-modal-footer {
  display: none !important;
}

.ant-modal-content {
  border-radius: 10px !important;
}
.popup-btn {
  width: 80% !important;
}
// .popup-btn__okay {

// }
