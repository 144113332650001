@import "src/styles/variables";

.pv-form__container {
  margin-top: 5rem;

  h1 {
    text-align: center;
    color: white;
    font-size: 24px;
    font-weight: bold;
  }
  .resend__otp {
    padding: 0px 15px;
    margin-top: 5%;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: white;
    opacity: 0.8;
    text-align: right;
    &:hover {
      opacity: 1;
    }
  }

  .btn__wrapper {
    margin-top: 1rem;
  }

  .dont-have-acc {
    color: white;
    font-size: 16px;
    margin-top: 1rem;
    span {
      color: $secondary-color;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .otp-wrapper {
    display: flex;
    margin-top: 1.5rem;
    justify-content: center;
  }
}
