.attachment__container {
  padding: 15px 0;
  .ant-btn {
    border-radius: 5px !important;
    background-color: #f2f3f5 !important;
    font-weight: 500;
    padding: 10px 10px;
    height: 100%;
    color: gray;
  }
}
.attached__image {
  width: 100%;
  height: 100%;
}
.input-clip__icon {
  color: #5d5d5d80 !important;
  margin: 5px;
}
.attached-image__close {
  position: absolute;
  right: -2%;
  top: -10%;
  color: #5d5d5d80 !important;
}
.attached-image__container {
  position: relative;
  width: 15vw;
  height: 15vh;
  margin: 10px;
}
