@import "src/styles/variables";

.leaderboard-module {
  .how-it-works__container {
    position: absolute;
    top: 5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      width: 15px;
      height: 15px;
      margin-left: 0.5rem;
    }
    p {
      color: #fff;
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .how-it-works__container {
    right: 0;
    margin-right: 5px;
  }
}
