@import "src/styles/variables";

.live-class-card {
    background-color: #fff;
    padding: 0 24px;
    box-shadow: 2px 2px 10px #0f47b20f;
    border-radius: 10px;
    margin-top: 24px;
    &.live {
        border-left: 2px solid #5539CB;
    }
    .live-class-card-date__container {
        border-right: 1px solid #eeeeee60;
        padding: 24px 0;
        .live-class-card__date {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 10px;
        }
        .live-class-card__time {
            color: $primary-grey;
            font-weight: 500;
            margin-bottom: 0;
            .anticon {
                margin-right: 8px;
            }
        }
    }
    .live-class-card-title__container {
        padding: 24px;
        .live-class-card__title {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 12px;
        }
        .live-class-card-more__container {
            display: flex;
            align-items: center;
            column-gap: 20px;
            p {
                margin-bottom: 0;
            }
            .live-class-card__tutors {
                display: flex;
                min-width: 10%;
                img {
                    width: 20px;
                    height: 20px;
                    border-radius: 10px;
                    margin-left: -5px;
                    border: 1px solid #FFFFFF;
                    &:first-child {
                        margin-left: 0;
                    }
                }
                span {
                    color: $primary-grey;
                    font-weight: 500;
                    margin-right: 5px;
                }
            }
            .live-class-card__subject {
                min-width: 15%;

                img {
                    width: 10px;
                    height: 13px;
                    margin-right: 5px;
                    margin-bottom: 2px;

                }
                font-weight: 500;
                color: $primary-grey;
            }
            .live-class-card__class {
                img {
                    width: 12px;
                    height: 12px;
                    margin-right: 10px;
                    margin-bottom: 2px;
                }
                font-weight: 500;
                color: $primary-grey;
            }
        }
    }
    .live-class-card__actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        button {
            height: 40px !important;
        }
    }
}

