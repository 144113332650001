@import "src/styles/variables";

.live-class-participants {
    .ant-drawer-header {
        display: none !important;
    }
    .ant-drawer-body {
        padding: 0 !important;
    }
    .ant-drawer-content-wrapper {
        box-shadow: none !important;
        height: 87.5vh !important;
    }
    .ant-drawer-content {
        border-radius: 10px 0 0 10px;
    }
    .live-class-participants__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #200e52;
        height: 49px;
        padding-left: 28px;
        padding-right: 24.54px;
        .live-class-participants-header__left {
            img,
            h4 {
                display: inline;
            }
            h4 {
                color: #fff;
                margin-bottom: 0;
                margin-left: 16px;
            }
            img {
                width: 18px;
                height: 18px;
            }
        }
        .live-class-participants-header__right {
            img {
                cursor: pointer;
            }
        }
    }
    .live-class-participants__list {
        padding-top: 3px;
        overflow-y: auto;
        height: 90%;
        .live-class-participants__list-item {
            padding: 14px 24px;
            .participant-profile__placeholder {
                width: 33px;
                height: 33px;
            }
            .participant-profile__uid {
                margin-bottom: 0 !important;
            }
            .participant-profile__audio,
            .participant-profile__video {
                width: 22px;
            }
        }
    }
}
