.continue-where-you-left__container {
  margin-top: 40px;
  width: 100%;
  .slick-list {
    margin: 30px !important;
  }
  .slick-next {
    right: 0 !important;
  }
  .slick-prev {
    left: 0 !important;
  }
}
.continue-where-you-left-no-btn__container {
  margin-top: 40px;
  width: 100%;
  .slick-list {
    margin: 30px 0 !important;
  }
}
.continue-where-you-left__wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 18px;
  margin: 0.5rem;
}

.video-wrapper {
  display: grid;
}

.slick-dots {
  visibility: hidden;
  button {
    background-color: red !important;
  }
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  font-size: inherit !important;
  color: gray !important;
  background-color: white !important;
  box-shadow: 2px 2px 10px #0f47b20f !important;
  border-radius: 2px !important;
  height: 25px !important;
  width: 23px !important;
}


@media only screen and (max-width: 768px) {
  .continue-where-you-left__container {
    margin-top: 10px;
    width: 100%;
    .slick-list {
      margin: 30px 0 !important;
    }

    .slick-next {
      display: none !important;
    }
    .slick-prev {
      display: none !important;
    }

    .pj-subheading {
      margin-top: 0rem;
      margin-bottom: 0rem;
  }
  }

  .continue-where-you-left__wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 18px;
    margin-bottom: 3rem;
    overflow-y: scroll;
  }
}
