@import "src/styles/variables";
.bottom-bar {
  display: none !important;
}


@media only screen and (max-width: 768px) {
  .sidebar {
    display: none !important;
  }

  .ant-layout-sider {
    display: none;
  }

  .sidebar__icon {
    width: 16px;
    height: 16px;
  }
  .bottom-bar {
    display: flex !important;
    justify-content: space-between;
    background-color: #f0f0f0 !important;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;


    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .bottombar__item {
      text-align: center;
      padding-top: 9px;
    }

    .ant-menu-item {
      span {
        margin-left: 0px !important;
        color: black;
      }
    }
  }

  .ant-menu-horizontal {
    line-height: 30px !important;
  }
}
