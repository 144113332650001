.dropdown-field {

  .ant-select {
    width: 100%;
  }
  .ant-select-selector {
    height: 3rem !important;
    border-radius: 6px !important;
    border: none !important;
  }
  .ant-select-single {
    .ant-select-selection-search {
      top: 10px !important;
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      padding-top: .5rem !important;
    }
  }
}
