.using-panditji__card {
  border: none !important;
  border-radius: 10px !important;
  padding: 5px 30px !important;
  height: 3rem !important;

  margin: 10px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .board__checkbox {
    margin-right: 25px !important;
    
    span {
      span {
        background-color: white !important;
        border: none !important;
      }
    }
  }
  span {
    border-radius: 10px !important;
  }
  p {
    display: block;
    margin: auto 20px;
    font-size: 18px;
    font-weight: 600;
    color: white;
  }
}
.using-card__border {
  border: 2px solid white !important;

}

.using-for__school {
  background-color: #0394df;
}
.using-for__exam {
  background-color: #11ac84;
}
.using-for__both {
  background-color: #db6e8b;
}

.disabled__div {
  pointer-events: none;
  opacity: 0.4;
}
