@import 'src/styles/variables';

.horizontal-divider {
  display: none;
}
.study-material__container {
  position: relative;
  // margin: -1.5rem;
  height: 100vh;
  overflow-y: scroll;
  margin-top: 1rem;
  .loading__container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .video-content {
    position: static;
    top: 0 !important;
    // .notes {
    //   // margin-top: 40px;
    //   background-color: $white-color;
    //   // padding: 1.5rem 2rem;
    //   box-shadow: 0px 0px 10px #6666660f;
    //   border-radius: 10px;
    //   h3 {
    //     font-weight: bold;
    //     color: $text-color;
    //   }

    //   p {
    //     font-size: 16px;
    //     color: #0c1013;
    //   }
    // }
  }

  .content__panel {
    display: flex;
    justify-content: space-between;
    margin-top: 61%;
    width: 100%;
  }

  .header {
    display: flex;
    width: 60%;
    margin-bottom: 2rem;
    span {
      margin-top: 5px;
      font-size: 20px;
      color: #0c1013;
      font-weight: bold;
    }
  }
  .nav-content__wrapper {
    margin-left: 2%;
  }
  .nav__content {
    font-size: 20px;
    color: #0c1013;
    margin: 0;
    font-weight: bold;
  }
  .nav__subject {
    font-size: 16px;
    color: #7e899e;
    margin: 0;
    margin-top: 5px;
  }

  .nav__collapse {
    width: 100%;
    padding-top: 1rem !important;
    background-color: white !important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    box-shadow: 2px 2px 10px #6666660f;
    .ant-collapse-header {
      padding-bottom: 5px !important;
    }
    .ant-divider-horizontal {
      margin: 0 25px !important;
      width: unset !important;
      min-width: unset !important;
    }
  }
  .video__body {
    padding: 1.5rem;
    width: 100%;
    height: 100%;
  }
  .panel__content {
    margin: 15px 25px;
  }
  .header__left {
    width: 50%;
  }
  .header__right {
    width: 50%;
  }

  .header-right__icons {
    margin: 0 10px;
  }
  .vote-active {
    color: #145ce7;
    margin-top: 5px;
    font-size: 12px;
  }
  .vote-inactive {
    color: #7e899e;
    margin-top: 5px;
    font-size: 12px;
  }
  .quick-quiz {
    color: #ff9810;
    margin-top: 5px;
    font-size: 12px;
  }
  .mark-complete {
    color: #7e899e;
    margin-top: 5px;
    font-size: 12px;
  }
  .content__notes {
    border: none !important;
    box-shadow: none !important;
    min-height: 150px !important;
  }
  .notes {
    color: #01215d;
    margin-top: 5px;
    font-size: 12px;
  }
}
.content__notes {
  border: none !important;
  box-shadow: none !important;
  min-height: 150px !important;
}
@media only screen and (max-width: 1024px) {
  .content__panel {
    display: block;
  }
  .header__right {
    margin-top: 1rem;
    width: 100%;
    .ant-row {
      justify-content: space-around;
    }
  }
  .video-content {
    margin-top: 25%;
  }
  .related-content__container {
    height: 100%;
    margin-top: 2rem;
    .related-video__wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 25px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .study-material__container {
    position: relative;
    height: 100vh;
    margin-top: 0;
    padding-bottom: 3rem;

    .loading__container {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .video-content {
      position: unset;
      top: 0 !important;
      margin-top: 0%;
    }

    .content__panel {
      display: block;
      margin-top: 0px;
      position: unset;
      background: #16102b;
    }

    .header {
      width: auto;
      margin-bottom: 0;
      position: absolute;
      top: 10px;
      z-index: 1;
      left: 5px;

      .nav__content {
        display: none;
      }

      span {
        margin-top: 0px;
      }
    }

    .video__body {
      padding: 0;
      height: auto;
    }

    .header__left {
      width: 100%;

      .nav__content {
        color: white;
        font-size: 16px;
        font-weight: unset;
      }
    }
    .header__right {
      width: 100%;
    }

    .notes {
      color: #7e899e;
    }

    .notes-icon {
      filter: invert(100%) sepia(3%) saturate(12%) hue-rotate(103deg) brightness(105%) contrast(105%);
    }
  }

  .related-content__container {
    height: 100%;
    margin-top: 2rem;
    .related-video__wrapper {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 25px;
    }

    .horizontal-divider {
      display: block;
    }
  }
}
