.questions-drawer__container {
	.fixed-opener {
		color: white;
		position: fixed;
        transition: margin-left 0.7s;
        top: 50vh;
        img {
            background-color: rgba($color: #fff, $alpha: .5);
            padding: 5px 12px 5px 5px;
            height: 30px;
            width: 28px;
            border-radius: 0px 15px 15px 0px;
            cursor: pointer;
            transition: background-color 0.7s;
        }
        span {
            color: transparent !important;
        }
	}

	.fixed-opener__move {
        margin-left: 35vw;
        img {
            background-color: rgba($color: #fff, $alpha: 1);
        }
	}
}
.questions-drawer-expand__wrapper {
	position: fixed;
	height: 100vh;
	width: 0vh;
	background-color: white;
    transition: width 0.7s;
}

.questions-drawer-expand {
    width: 35vw;
}


@media only screen and (max-width: 767px) {
  .questions-drawer-expand {
    width: 100%;
    z-index: 1;
}

.questions-drawer__container {
	.fixed-opener {
		z-index: 2;
	}

	.fixed-opener__move {
        z-index: 2;
	}
}
}
