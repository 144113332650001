@import 'src/styles/variables';

.pj-heading {
    font-size: 24px;
    color: $text-color;
    font-weight: 700;
}
.pj-subheading {
    font-weight: 600;
    font-size: 18px;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
}

@media only screen and (max-width: 768px) {
    .pj-subheading {
        margin-top: 0rem;
    }
}
