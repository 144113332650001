@import "src/styles/variables";

.checkout-card {
    background-color: #fff;
    padding: 24px 24px;
    box-shadow: 2px 2px 10px #0f47b20f;
    border-radius: 10px;

    // margin-top: 24px;
    // cursor: pointer;



    .white-wrap {
        padding: 0.5rem 0rem 0rem;
        background: #ffffff;
    }

    .cart-heading {
        display: flex;
        flex-direction: column;

        .__text {
            color: #56595f;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 0px;
        }

        .__title {
            font-size: 24px;
            font-weight: bold;
            line-height: 1.2;

        }

        .__sub_title {
            font-size: 15px;
            font-weight: lighter;
            line-height: 1.2;
            text-decoration: line-through;
            color: #56595f;
            margin-left: 10px;

        }
    }



    .main-btn-3 {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        text-align: center;
        vertical-align: middle;
        border: 0;
        padding: 0 4.5rem;
        font-size: 16px;
        line-height: 40px;
        cursor: pointer;
        background-color: #D75878;
        color: #fff;
        border-radius: 5rem;
    }


    &.btn {
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition: 0.2s;

        &::before {
            content: '';
            height: 3px;
            width: 0;
            left: 0;
            bottom: 0;
            position: absolute;
            background: #5539CB;
            transition: 0.4s;
        }

        &:hover {
            &::before {
                width: 100%;
            }
        }

        &:active {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }



}