.previous-paper__wrapper {
  padding-top: 5rem;
}
.previous-paper__card {
  border-radius: 10px;
  box-shadow: 2px 2px 10px #0f47b20f;
  background-color: white;
  display: flex;
  padding: 20px 10px;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 1.5rem;
}
.previous-paper-card__index {
  color: #7e899e;
  background-color: rgba(126, 137, 158, 0.1);
  border-radius: 50px;
  padding: 5px 10px;
}
.previous-paper-card__body {
  width: 75%;
}
.previous-paper__name {
  font-size: 18px;
  color: #0c1013;
  margin: 5px 0;
}
.previous-paper__time {
  font-size: 14px;
  color: #7e899e;
  margin: 0;
 
}
.previous-paper__start {
  background: transparent linear-gradient(256deg, #145ce7 0%, #0f47b2 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  color: white;
  width: 10%;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}
.previous-paper__result {
  background: transparent;
  border-radius: 10px;
  border: 1px solid rgb(126, 137, 158);
  color: rgb(126, 137, 158);
  width: 10%;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}