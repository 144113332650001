.input-field {
  background: #5839CB !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  padding: 10px !important;
  text-indent: 15px;
  height: 50px !important;
  color: white !important;
  input {
    text-indent: 15px;
  }
  img {
    width: 15px !important;
    height: 15px !important;
  }
  ::placeholder {
    color: #9abafc !important;
  }
  .ant-input-suffix {
    margin-right: 10px !important;
  }
  .anticon {
    color: #EDEAF8 !important;
    font-size: 15px !important;
  }
  
  .ant-picker-clear {
    background: #1b5ee0 !important;
    color: white !important;
  }
}
