@import "src/styles/variables";

.live-class-videos {
    width: 100%;
    transition: width 0.2s ease 0s;
    height: inherit;
    .live-class-video-player__stopped {
        width: 178px;
        height: inherit;
        background-color: #4f4f4f;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .live-class-video-player-stopped__name {
        position: absolute;
        left: 3%;
        bottom: 3%;
        color: #fff;
    }
    .live-class-video-player__stopped.host {
        width: 100%;
        height: 69vh;
        background-color: #4f4f4f;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 150px;
            height: 150px;
        }
    }
    .live-class-video-player__stopped.student {
        width: 100%;
        height: 84vh;
        background-color: #4f4f4f;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 150px;
            height: 150px;
        }
    }
    .live-class-video-player {
        width: inherit;
        div {
            border-radius: 10px !important;
            background: none !important;
        }
        
    }
    .live-class-video-player.host {
        height: 84vh;
        .agora_video_player {
            object-fit: contain !important;
            background-color: #4f4f4f !important;
        }
    }
    .live-class-video-player.host.participants-joined {
        height: 69vh;
    }
    .participants-videos__wrapper {
        display: flex;
        column-gap: 6px;
        overflow-x: scroll;
        height: 100px;
        margin-bottom: 12px;
    }
    .live-class-video-player.participants {
        width: 178px;
        height: 100%;
    }
    .live-class-video-player__wrapper {
        position: relative;
    }
}
.live-class-videos__drawer-shown {
    width: calc(100% - 378px);
}
.live-class-video-player__name {
    position: absolute;
    left: 8px;
    bottom: 3px;
    background-color: #0c0137;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    padding: 1px 4px;
    border-radius: 6px;
}
