@import "src/styles/variables";

.product-details__container {
  .product-details__header {
    display: flex;

    img {
      width: 30px;
      height: 30px;
    }
    .product-details-header__content {
      margin-left: 1rem;
      p {
        margin: 0;
      }
      .product-details-header-content__title {
        font-weight: 700;
        margin-bottom: 0.5rem;
      }
      .product-details-header-content__desc {
        color: #7e899e;
        font-size: 12px;
      }
    }
  }
  .product-details__body {
      margin-top: 2rem;
    .product-details-point__container {
      display: flex;
      margin-bottom: 2rem;
      p {
          margin: 0;
      }
      .product-details__point {
        font-weight: 700;
        font-style: italic;
        color: #feaa2e;
        width: 25%;
      }
      .product-details-point__desc {
        font-weight: 500;
        width: 70%;
      }
    }
  }
}
.product-details {
    width: 420px !important;
  }