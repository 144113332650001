.list__exercises {
  border-radius: 10px;
  background-color: white;
  height: 70vh;
  margin: 5% 2%;
  .content__wrapper {
    border-right: 1px solid rgb(243, 243, 243);
    height: 100%;
    padding-top: 2%;
    // -ms-overflow-style: none;
    // scrollbar-width: none;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    .ant-tabs-top > .ant-tabs-nav::before {
      border-bottom: none !important;
    }
    .exercises-seach__input {
      background-color: #f2f3f5;
      border-radius: 10px;
      border: none;
      box-shadow: none;
      text-indent: 10px;
      outline: none;
      color: #7e899e;
      span {
        color: #7e899e;
      }
      input {
        background-color: transparent;
        text-indent: 25px;
      }
    }
    .content-left__tabs {
      height: 100%;
      // -ms-overflow-style: none;
      // scrollbar-width: none;
      // &::-webkit-scrollbar {
      //   display: none;
      // }

      .ant-tabs-nav-list {
        margin: 0 3%;
      }
      .ant-tabs-content {
        height: 100%;
      }
    }
    .content-left__wrapper {
      height: 100%;
    }
    .content-left__header {
      padding: 2rem 3rem 2rem 3rem;
      display: flex;
      justify-content: space-between;
      p {
        font-size: 20px;
        font-weight: 600;
      }
      span {
        font-size: 24px;
      }
    }
    .subject__card {
      padding: 2rem 3rem;
      p {
        font-size: 16px;
        font-weight: 500;
      }

      .subjects-details__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 50%;
        p {
          margin: 0;
          font-size: 14px;
          font-weight: 500;
          color: #54c1e3;
        }
        .progress__wrapper {
          .ant-progress {
            width: 30%;
          }
          display: flex;
          width: 60%;
          p {
            color: #56c2b8;
            margin: 0;
            margin-left: 10%;
          }
        }
      }
    }
  }
  .content__right {
    height: 100%;
    overflow: auto;
    padding-bottom: 5%;
    // -ms-overflow-style: none;
    // scrollbar-width: none;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    .content-right__header {
      padding: 3rem 3rem 1rem 3rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 20px;
        font-weight: 600;
        margin: 0;
      }
      .content-right__btn {
        border: 1px solid #5839cb;
        padding: 2px 5px;
        border-radius: 10px;
        font-size: 12px;
        width: fit-content;
        color: #5839cb;
        cursor: pointer;
        span {
          font-size: 9px;
        }
      }
    }
  }
}
