.register__container {
  height: 100%;
  .register-container__wrapper {
    padding: 5%;
  }
  .image__wrapper {
    margin: auto;
    text-align: center;
    img {
      width: 90%;
      margin-top: 15%;
    }
  }
  .mindjjo__logo {
    margin-top: 3rem;
    width: 200px;
    height: 60px;
  }
  .step__counter {
    margin-top: 3rem;
    display: flex;
    text-align: center;
    justify-content: space-between;
    background-color: #1b5ee0;
    color: rgba($color: white, $alpha: 0.5);
    border-radius: 25px;
    :first-child {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
    }
    :last-child {
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }
    .current__step {
      background-color: rgba($color: white, $alpha: 0.5);
    }
    .step__item {
      padding: 10px 10px;
      width: 100%;
      text-align: center;
    }
    .visited__step {
      background: #f7971e !important;
    }
  }
}
.register-form__container {
  margin-top: 5rem;

  h1 {
    color: white;
    font-weight: bolder;
    text-align: left;
    font-size: 24px;
  }
  .btn__wrapper {
    margin-top: 2rem;
  }
}
.building__logo {
  display: block;
  margin: 5rem auto;
}
