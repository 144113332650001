.background-image-div {
  background-image: url('./../../../assets/images/mobile_img.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 900px;

  img {
    position: relative;
    top: 100px;
    width: 191px;
    height: 67px;
    left: 25%
  }
}

.main-heading {
  color: #ffffff;
  font-size: 24px;
  text-align: center;
  padding-top: 165px;
}
