@import "src/styles/variables";

.add-profiles__container {
  margin-top: 5rem;

  h1 {
    color: white;
    font-size: 24px;
    font-weight: bold;
  }
  color: white;
  .close__text {
    color: $secondary-color;
    text-align: center;
    margin-top: 1rem;
    cursor: pointer;
  }
  .add-profiles__title {
    font-size: 24px;
    font-weight: bold;
    color: white;
    span {
      font-weight: normal;
    }
  }
  .student__wrapper {
    margin-top: 10%;
    text-align: center;
    height: 35vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 10px;
      &:hover {
        background: rgba(255, 255, 255, 0.4);
      }
    }
    .student__container {
      img {
        margin: 1rem;
        width: 100px;
        height: 100px;
        border-radius: 50px;
      }
      p {
        font-size: 16px;
        font-weight: 600;
        color: white;
      }
    }
    .student__container-blocked {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .add-new__profile {
    cursor: pointer;
    margin: auto;
    margin-top: 1rem;
    width: 100px;
    height: 100px;
    font-size: 65px;
    text-align: center;
    color: rgba(255, 255, 255, 0.4);
    background: #5839CB;
    border-radius: 50px;
  }
  .btn__wrapper {
    margin-top: 4rem;
  }
  .profiles__wrapper {
    margin: auto;
  }
}
@-webkit-keyframes wobble {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  33.3% {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes wobble {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  33.3% {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.wobble {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.wobble:active {
  -webkit-animation-name: wobble;
  animation-name: wobble;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
.student__warning {
  text-align: center;
  margin: 2%;
  color: $danger-color;
}
