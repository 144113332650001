.switch__profile {
  .ant-modal-content {
    background: white;
  }
  width: 30% !important;
  p {
    text-align: center;
    cursor: pointer;
  }
}
.card__footer {
  p {
    text-align: left;
  }
}
.switch-profile__avatar {
  margin-top: -2rem;
  text-align: center;
  position: relative;
}
.switch-profile__content {
  text-align: center;
  margin-top: 3rem;
  p {
    font-weight: 600;
    font-size: 18px;
  }
}
.school-board__card {
  // background: transparent linear-gradient(65deg, #3475f6 0%, #1b5ee0 100%) 0% 0%
  //   no-repeat padding-box !important;
  border: 2px solid #528eff !important;
  border-radius: 10px !important;
  padding: 10px !important;
  height: 85px !important;
  color: white !important;
  margin: 10px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    margin: 0;
    font-weight: bold;
  }
  p:nth-child(2) {
    font-size: 12px;
    font-weight: normal;
  }
}
.card__icon {
  width: 60px;
  height: 60px;
  border-radius: 5px;
}

.add-new__profile {
  cursor: pointer;
  text-align: center;
  background: rgba(88, 57, 203, 0.1);
  border-radius: 50px;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 35px;
    height: 35px;
  }
  
}

.switch-profile-avatar__image {
  // border: 4px solid #1257da;
  border-radius: 50px;
  width: 100px;
  height: 100px;
}
.switch-profile-avatar__container {
  margin: 1rem;
  text-align: center;
  p {
    font-weight: bold;
    margin: 1rem;
  }
}
.profiles__wrapper {
  margin: auto;
}