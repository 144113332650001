@import "src/styles/variables";

.controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .controls__container {
        display: flex;
        column-gap: 72px;
        padding-left: 56px;
        .controls__item {
            text-align: center;
            img {
                width: 24px;
                height: 24px;
                cursor: pointer;
            }
            p {
                margin-bottom: 0;
                font-size: 12px;
                font-weight: 500;
                margin-top: 6px;
            }
            &:first-child {
                width: 40px;
            }
            &:nth-child(2) {
                width: 60px;
            }
            &:nth-child(3) {
                width: 90px;
            }
            &:last-child {
                width: 40px;
            }
        }
        
    }
    .end-btn__container {
        padding-right: 40px;
        button {
            height: 40px !important;
        }
    }

}
