.student-details__container {
  margin-top: 5rem;

  h1 {
    color: white;
    font-size: 24px;
    font-weight: bold;
  }
  color: white;
  .btn__wrapper {
    margin-top: 8rem;
  }
}
.dob__field {
  div {
    input {
      color: white;
    }
    span {
      span {
        svg {
          color: white;
          opacity: 0.5;
        }
      }
    }
  }
}
