.login__container {

 .input-field {
    input {
      background-color: transparent;
      text-indent: 15px;
      color: white !important;
    }
  }
  .login-container__wrapper {
    padding: 5%;
  }

  .image__wrapper {
    margin: auto;
    text-align: center;
    img {
        width: 90%;
        margin-top: 15%;
    }
  }
  .mindjjo__logo {
    margin-top: 3rem;
    width: 200px;
    height: 60px;
  }
}
