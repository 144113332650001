@import "src/styles/variables";

.query-coming-soon {
  background: url("../../../../assets/images/query-launcing-soon.svg") no-repeat;
  background-size: 102%;
  width: 100%;
  height: 100%;
  position: relative;
  border-bottom-left-radius: 35px;
  border-top-left-radius: 35px;
  .content {
    position: absolute;
    top: 38%;
    left: 5%;
    h1 {
      color: white;
      font-size: 70px;
      font-weight: 700;
      margin: 0;
    }
    p {
      color: white;
    }
    .description {
      font-size: 24px;
    }
    .launching__soon {
      display: flex;
      margin-top: 4rem;
      p {
        font-size: 24px;
        margin: 0;
        margin-left: 1rem;
      }
    }
  }
}
@media (min-width: 1200px) and (max-width: 1599px) {
  .query-coming-soon {
    background-size: 110% !important;
  }
}