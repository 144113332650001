.navbar-item {
  float: right;
}
.navbar-container__float {
  position: absolute;
  width: 95%;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-input {
    background-color: rgba(126, 137, 158, 0.1) !important;
    box-shadow: none !important;
    border: none !important;
    input {
      background-color: transparent !important;
    }
  }
}
.navbar__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content-left {
  width: 50%;
  .heading {
    display: flex;
    h1 {
      margin: auto 0;
    }
    img {
      margin-right: 20px;
      cursor: pointer;
    }
  }
  p {
    color: #0c0137;
    //  margin-left: 2.5rem;
    font-size: 16px;
  }
}
.content-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
  .search-input__container {
    width: 40%;
    margin-right: 5%;
  }
  .search-input {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 2px 2px 10px #0f47b20f;
    border-radius: 10px;
    height: 40px;
    input {
      text-indent: 10px;
    }
  }
  .profile__container {
    display: flex;
    width: 30%;
    cursor: pointer;
    .profile-image__container {
      width: 50px;
      height: 50px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 25px;
      }
    }
    .profile-name__container {
      margin-left: 5%;
      width: 70%;
      p {
        font-size: 14px;
        color: #7e899e;
        margin: 0;
      }
      .profile__name {
        color: #222222;
        font-weight: 600;
        font-size: 16px;
        span {
          color: #7e899e;
          margin-left: 3%;
        }
      }
    }
  }
}
.notification-container {
  width: 30px;
  height: 30px;
  margin-right: 5%;
cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
  .ant-badge-count {
    background-color: #5839cb;
    .ant-scroll-number-only-unit {
      color: white;
    }
  }

  .ant-badge.ssvmkidscasa {
    .ant-badge-count {
      background-color: #136367 !important;
    }
  }
}
.subtext {
  margin-bottom: 0;
}
.subtext--margin {
  margin-left: 2.4rem;

}
.app-logo {
  display: none;
}
@media (min-width: 1200px) and (max-width: 1599px) {
  .content-right {
    width: 70% !important;
  }
}

@media only screen and (max-width: 768px) {
  .navbar__container {
    position: absolute;
    z-index: 1;
    margin-top: 15px;
    width: 100%;
    justify-content: right;
  }

  .content-left {
    display: none;
  }

  .app-logo {
    display: block;
    img {
      width: 100px;
      margin-left: 5px;
    }

  }

  .content-right {
    width: 90%;

    .profile__container {
      width: unset;
      margin-right: 5px;
    }
    .notification-container, .profile-name__container {
      display: none;
    }

  }
}
