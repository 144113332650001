@import "src/styles/variables";

.live-class {
    .skeleton-loader {
        background-color: #fff;
        padding: 16px;
        box-shadow: 2px 2px 10px #0f47b20f;
        border-radius: 10px;
        margin-top: 24px;
        &:first-child {
            margin-top: 5em;
        }
    }
    .no-live-class-text {
        font-size: 12px;
        text-align: center;
        margin-top: 2em;
    }
    .live-class-live-now {
        padding: 1rem 0 2rem;
        h3 {
            color: #fff;
            font-weight: 600;
        }
    }
    .live-class-upcoming-classes {
        padding: 1rem 0;
        h3 {
            font-weight: 600;
            color: #fff;
        }
        &.live {
            h3 {
                color: $primary-black;
            }
        }
    }
    .no-live {
        h3 {
            color: #fff;
        }
        .no-live-class-text {
            color: #fff;
        }
    }
}
