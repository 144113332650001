@import "src/styles/variables";

.metrics-description {
    width: 450px !important;
  .metrics-description__wrapper {
    padding: 0 1rem;
    .metric__container {
      display: flex;
      margin-top: 2rem;
      .metric___image {
        width: 55px;
        height: 45px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .metric-desc__container {
        margin-left: 7%;
        p {
          font-size: 16px;
          margin: 0;
          color: #7e899e;
          text-transform: lowercase;
        }
        .metric__name {
          font-weight: 700;
          font-size: 18px;
          color: #0c0137;
          text-transform: capitalize;
          margin-bottom: 3%;
        }
      }
    }
  }
  .metric__btn {
    width: 75%;
    margin: 15% auto 3% auto;
    button {
      justify-content: center !important;
    }
  }
}
