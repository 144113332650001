@import "src/styles/variables";

.update-password {
    margin-top: 5rem;
    h2 {
        color: white;
        font-weight: bolder;
        text-align: left;
    }
    .forgot-password__login {
        color: white;
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
        span {
            color: $secondary-color;
            cursor: pointer;
        }
    }

	.btn__wrapper {
		margin-top: 12rem;
	}
}

@media only screen and (max-width: 600px) {
    .form__wrapper {
        margin: 2rem;
    }
}
