@import "src/styles/variables";

.points-history {
    background-color: white;
    border-radius: 10px;
    padding: 2.5rem;
    .points-history__wrapper {
        margin-top: 2rem;
        .points-hisory__card {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid rgb(245, 245, 245);
            padding: 1.5rem 2rem 1.5rem  0;
            .points-hisory-card__left {
                display: flex;
                align-items: center;
                width: 70%;
                .points-history-content__image {
                    width: 50px;
                    height: 50px;
                    border-radius: 8px;
                    margin-right: 1.5rem;
                }
                .points-history-content__container {
                    width: 70%;
                    p {
                        margin: 0;
                    }
                 .points-history-content-title__container {
                     display: flex;
                     align-items: center;
                     margin-bottom: 0.4rem;
                     .points-history-content__title {
                         font-size: 12px;
                        margin-right: 1rem;
                        color: #FD9000;
                        font-weight: 500;
                        span {
                            img {
                                width: 15px;
                                height: 15px;
                                margin-right: 0.5rem;
                            }
                        }
                     }
                     .points-history-content__type {
                        margin-left: 1rem;
                        font-size: 12px;
                        font-weight: 300;
                        color: #7E899E;
                     }
                 }
                 .points-history-content__name {
                     font-size: 13px;
                 }
                }
            }
            .points-hisory-card__right {
                display: flex;
                align-items: center;
                p {
                    margin: 0;
                    margin-right: 1rem;
                    font-weight: 700;
                    font-style: italic;
                    color: #FEAA2E;
                }
                img {
                    width: 25px;
                    height: 25px;
                }
            }
            &:last-child {
                border: none;
                padding-bottom: 0;
            }
        }
    }
    .no-points-history__text {
        color: gray;
    }
}

@media only screen and (max-width: 768px) {
  .points-history {
    height: 58vh;
    overflow-y: scroll;
    position: relative;
    top: 200px;
    padding: 11% 3px 0 3px;
    .points-history__wrapper {
      .points-hisory__card {
        padding: 1.5rem 0rem 1.5rem 0;

        .points-hisory-card__left {
          .points-history-content__image {
            display: none;
          }
          .points-history-content__title {
            display: flex;
          }
          .not-mobile {
            display: none;
          }
          .points-history-content__container {
            width: unset;
          }
        }
      }
    }
  }
}
