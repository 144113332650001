.list-all-chapters__container {
    .contents__count {
        margin-left: 2.4rem;
    }
  .chapter__outer__container {
    // display: grid;
    // grid-template-columns: repeat(4, 1fr);
    // grid-column-gap: 20px;
    // grid-row-gap: 40px;
    width: 99%;
    margin-top: 2rem !important;
  }
}
