@import 'src/styles/variables';

.already-have-acc {
    color: white;
    font-size: 16px;
    margin-top: 1rem;
    span {
        color: $secondary-color;
        font-weight: bold;
        cursor: pointer;
    }
}
.btn__wrapper {
    p {
        text-align: center;
    }
}
.terms-and-conditions__checkbox {
    padding-top: 1rem !important;
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $secondary-color !important;
        border-color: $secondary-color !important;
        &:hover {
            border-color: $secondary-color !important;
        }
    }
}
.terms-and-conditions {
    color: white;
    text-align: center;
    span {
        color: $secondary-color;
        cursor: pointer;
    }
}
.register-form {
    margin-top: 2rem;
    .input-field {
        input {
            background-color: transparent;
            text-indent: 15px;
            color: white !important;
        }
        .ant-input-suffix {
            margin-right: 10px;
        }
    }

    h1 {
        color: white;
        font-weight: bolder;
        text-align: left;
        font-size: 24px;
        margin-bottom: 0px;
    }
    .btn__wrapper {
        margin-top: 1.5rem;
    }
}
.ant-picker-input {
    .ant-input-suffix {
        margin-right: 20px !important;
    }
}

.ant-input-group-compact {
    .ant-input {
        background: #5839cb !important;
        border: none !important;
        box-shadow: none !important;
        text-align: left !important;
        padding: 10px !important;
        text-indent: 15px;
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
        height: 50px !important;
        color: white !important;
    }

    .ant-select-selector {
        background: #5839cb !important;
        border: none !important;
        box-shadow: none !important;
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        padding: 10px !important;
        height: 50px !important;
        color: white !important;

        .ant-select-selection-search {
            input {
                background: #5839cb !important;
                border: none !important;
                box-shadow: none !important;
                text-align: left !important;
                border-top-left-radius: 10px !important;
                border-bottom-left-radius: 10px !important;
                padding: 10px !important;

                height: 50px !important;
                color: white !important;
            }
        }
    }

    .ant-select-arrow {
        color: white !important;
    }
}
