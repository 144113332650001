@import "src/styles/variables";

.profile-upload {
  display: flex;
  justify-content: center;
  margin: 5%;
  .profile-upload__wrapper {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: rgba(248, 248, 250, 0.5);
    position: relative;
    img {
      width: 100px;
      height: 100px;
      border-radius: 50px;
    }
  }
  .profile-upload__camera {
    color: white;
    width: 32px;
    height: 32px;
    text-align: center;
    border-radius: 16px;
    font-size: 16px;
    padding-top: 2px;
    position: absolute;
    right: -5%;
    top: 3%;
    background-color: #5839cb;
  }
  .profile-upload__user {
    color: white;
    font-size: 40px;
    position: absolute;
    top: 20%;
    left: 30%;
  }
}
