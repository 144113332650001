@import "src/styles/variables";

.announcement-comment {



    .announcement-comment__input {
        padding: 2px 20px;
        padding-bottom: 10px;
        display: flex;

        .ant-btn {
            width: 32px !important;
            height: 32px !important;
            border-radius: 50% !important;
        }

        .ant-btn-primary[disabled] {
            color: rgba(0, 0, 0, 0.25) !important;
            border-color: #d9d9d9 !important;
            background: #d7d1d1 !important;
        }

        input {
            border-radius: 20px;
        }
    }

    .announcement-comment__card {
        padding: 5px 24px;
        display: flex;

        .announcement-comment__text {

            padding: 5px 10px;
            border-radius: 10px;
            background-color: #fff;
            width: 100%;
            display: grid;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.5;

            .announcement-comment__postby {
                font-size: 12px;
                font-weight: 600;
                color: gray;

            }

            .announcement-comment__data {
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }


}