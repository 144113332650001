.profile-modal {
  top: 0 !important;
  right: -19% !important;
  .ant-modal-content {
    border-radius: 0 !important;
  }
  .ant-modal-body {
    padding: 0px !important;
  }
  .profile-modal__wrapper {
    background-color: rgb(255, 255, 255);

    .profile-image__container {
      width: 150px;
      height: 150px;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 80%;
        height: 80%;
        border-radius: 75px;
      }
    }
    .profile-content__container {
      background-color: #f0f0f078;
      padding: 24px;
      .profile-welcome__note {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
      }
      .profile-courses__container {
          margin-top: 2rem;
        .profile-courses-card__selected {
          border: 2px solid #5839cb !important;
        }
        .profile-courses__card {
          display: flex;
          align-items: center;
          background-color: white;
          border-radius: 10px;
          margin: 1.5rem 1rem;
          padding: 1rem;
          box-shadow: 2px 2px 15px #0f47b21a;
          border: 2px solid white;
          .profile-courses-checkbox__container {
            width: 10%;
            .profile-courses__uncheck {
              width: 23px;
              height: 23px;
              border: 2px solid #7e899e;
              border-radius: 12px;
            }
            .profile-courses__check {
              font-size: 23px;
              color: #5839cb;
            }
          }
          .profile-course__logo {
            width: 60px;
            height: 60px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .profile-course__details {
            margin-left: 10%;
            p {
              margin: 0;
              font-size: 18px;
            }
            .profile-course__board {
              font-weight: 600;
            }
            .profile-course__name {
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }
      .profile-menus__container {
        padding: 10px;
        cursor: pointer;
        .ant-divider {
          margin: 18px 12px;
        }
        .ant-divider-horizontal {
          width: unset !important;
          min-width: unset !important;
        }
        .profile__menu {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .profile-menu__wrapper {
            display: flex;
            align-items: center;
            width: 70%;
          }
          .profile-menu__icon {
            width: 18px;
            height: 18px;
            margin: 0 5%;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .profile-menu-proceed__icon {
            width: 30px;
            height: 30px;
            border-radius: 15px;
            img {
              margin-left: 35%;
              margin-top: 15%;
              width: 10px;
              height: 10px;
            }
            background-color: rgba(126, 137, 158, 0.1);
          }
          .profile-menu__title {
            font-size: 20px;
            margin: 0;
            margin-top: 3%;
            text-align: start;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .profile-modal {
    right: unset !important;
  }
}
